<template>
    <el-row style="margin-top: 15vh;">
        <div :style="isMobile()?{'width': '99%'}:{'width': '50%'}" style="margin: 0 auto; border: 1px solid #17484C;">
            <div style="height: 4vh; background-color: #17484C;">
                <el-row style="padding: 0 2vw;">
                    <div class="yooniDisclaimer">
                        You're Nearly There
                    </div>
                </el-row>
            </div>
            <div style="background-color: #ffffff;">
                <el-row style="padding: 0 2vw;">
                    <div class="yooniDisclaimerHead">
                        Remember, the results from the Skillsvista recommender are meant as a supplement to decisions!
                    </div>
                </el-row>
                <el-row style="padding: 0 2vw 1vw;">
                    <div class="yooniDisclaimerDesc">
                        It is important to note that the results provided to you today are meant as a supplement to the
                        current guidance resources available to you. The courses recommended to you are to be used as a
                        guideline and are NOT to be interpreted as what you must do.
                        <br>It is the students responsibility to ultimately check that they meet specific course
                        requirements and CAO points before committing to any applications. Skillsvista does not take
                        responsibility for any errors made as a result of our recommendations.
                    </div>
                </el-row>
                <el-row style="padding: 0 2vw 1vw;">
                    <div style="float: right">
                        <el-checkbox v-model="termConditionsChecked">I have read and understand the
                            disclaimer
                        </el-checkbox>
                    </div>
                </el-row>
            </div>
        </div>
        <el-row style="width: 50%; margin: 0 auto; padding: 0 2vw 1vw;">
            <el-button @click="$emit('startGeneratingRecommendations')" class="black-primary-button takeQuizButton"
                       style="margin-top: 2%;float:right;"
                       v-if="termConditionsChecked">
                Find my Skillsvista Fit
            </el-button>
        </el-row>
    </el-row>

</template>

<script>
  export default {
    data: function() {
      return {
        termConditionsChecked: false,
      };
    },
    name: 'RecommendationDisclaimer',
  };
</script>

<style scoped>
    .yooniDisclaimerDesc {
        color: #000000;
        font-weight: normal;
        font-style: normal;
        font-family: Lato, serif;
        text-align: justify !important;
    }

    .yooniDisclaimer {
        color: white;
        font-weight: 600;
        font-style: normal;
        font-family: Lato, serif;
        text-align: left;
    }

    .yooniDisclaimerHead {
        font-family: Niramit, serif !important;
        font-style: normal;
        font-weight: 300;
        font-size: 22px;
        line-height: 20px;
        color: #000000;
        text-align: justify;
        margin: 2vh 0;
    }
</style>