<template>
    <el-card class="career_fit">
        <el-row style="display: flex; align-items: center;">
            <el-col :span="20" class="courseTopicTitle">Career Fit Sector</el-col>
            <el-col :span="4" v-if="courseDetails.percent !=='NA'" class="test-labels">
                <div style="text-align: center; width: 100%;">{{courseDetails.percent}}%</div>
            </el-col>
            <el-col :xl="2" :lg="2" :md="2" :sm="3" :xs="3" v-else-if="status === 0"
                    @click="$router.push({path: '/login/', query: { redirect: $route.fullPath }})">
                <div class="test-labels">
                    <img :src="`${publicPath}img/lock-image.svg`" style="width: 80%; margin: 0 auto;" alt="Lock">
                </div>
            </el-col>
        </el-row>
        <el-row>

            <el-col :span="24" v-for="(careerFit, index) in courseDetails.careers"
                    :key="index">
                <div class="careerName">
                    <div @click="$router.push(`/sector?name=${getCareerFit(careerFit)}`)">
                        {{careerFit}}
                        <i class="el-icon-arrow-right"></i>
                    </div>
                </div>
            </el-col>
        </el-row>
    </el-card>
</template>

<script>
  export default {
    name: 'CareerFit',
    props: ['courseDetails', 'status'],
    methods: {
      getCareerFit: function(careerFit) {
        return careerFit.replace('&', 'and');
      },
    },
    data: function() {
      return {
        component: {
          title: 'Career Fit Sector',
        },
        careerArr: [
          {
            name: 'Career Fit Sector',
          },
        ],
      };
    },
  };
</script>

<style scoped>
    .career_fit >>> .el-card__body {
        padding: 0 !important;
    }

    .career_fit {
        margin: 1vh 1vw;
        box-shadow: 0 0 0 0;
        background: #DEE1EE;
        border-radius: 4px;
        padding: 16px;
    }

</style>
