<template>
    <div class="main-report">
        <el-row :gutter="20" class="main-class-1">
            <el-row>
                <div class="salary-head" style="margin-bottom: 10px;">
                    Your top fitting career sectors
                </div>
            </el-row>
            <el-row class="career-fit-cards">
                <div :key="index"
                     class="course-div-1" v-for="(obj, index) in careerFitData">
                    <el-card @click.native="openPopover(obj)" class="course-card-1">
                        <div class="index-class">
                            <svg fill="none" viewBox="0 0 50 50" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="25" cy="25" fill="#17484C" r="23"/>
                                <text dominant-baseline="middle" fill="white" text-anchor="middle" x="50%" y="50%">
                                    {{index + 1}}
                                </text>
                            </svg>
                        </div>
                        <img :src="getImagePath(obj)" alt="no image" class="image">
                        <el-row>
                            <div class='career-title'>{{obj.name}}</div>
                        </el-row>
                        <el-row>
                            <div class="career-desc">{{obj.sd}}</div>
                        </el-row>
                        <el-row>
                            <button @click="openPopover(obj)" class="course-explore-button">
                                Find out more
                            </button>
                        </el-row>
                    </el-card>
                </div>
            </el-row>
        </el-row>
        <el-row>
            <div class="salary-head" style="margin-top: 10px;">
                Salary Per Sector
            </div>
        </el-row>
        <el-row>
            <el-col :span="!isMobile() ? 10: 24"
                    :style="!isMobile() ? {'width': '40vw !important'}: {'width': '100vw'}"
                    style="margin-top: -5vh;">
                <div :style="!isMobile() ? {'width': '40vw !important'}: {'width': '90vw', margin: 'auto'}"
                     id="my-chart"></div>
            </el-col>
            <el-col :span="12" style="margin-top: -5vh;" v-if="!isMobile()">
                <div style="width: 40vw !important;">
                    <div id="my-chart-1" style="width: 40vw !important;"></div>
                </div>
            </el-col>
        </el-row>

        <popover-block v-if="showPopover" 
                :data="popoverData" 
                :dialogVisible="showPopover"
                :style="isMobile() ? {'padding': '0 5vw'}:{'padding': '0 15vw'}"
                @closeDialog="closeDialog"/>
    </div>
</template>

<script>
  import PopoverBlock from './PopoverBlock';
  import {store} from '../../store';

  export default {
    name: 'CareerFitResult',
    components: {PopoverBlock},
    mounted() {
      this.$store.state.loadingScreen = true;
      let echarts = require('echarts');
      let ctx = document.getElementById('my-chart');
      let option = JSON.parse(JSON.stringify(this.chartData));
      option.yAxis.axisLabel = {
        ...option.yAxis.axisLabel,
        textStyle: {
            color: 'white'
        }
      };
      let instance = echarts.init(ctx, null, {renderer: 'svg', height: '600px'});
      $(window).on('resize', function() {
        if (instance !== null && instance !== undefined) {
          instance.resize();
        }
      });
      if (!this.isMobile()) {
        option.grid.right = '-20%';
        option.grid.left = '-1%';
        option.series[0].data = store.state.careerTestResults.graph_data.data.slice(17, 33);
        option.yAxis.data = store.state.careerTestResults.graph_data.career_names.slice(17, 33);
        // option.series[0].color = colorPalette.slice(17, 33)
        instance.setOption(option);
        let ctx1 = document.getElementById('my-chart-1');
        let option2 = JSON.parse(JSON.stringify(this.chartData));
        option2.grid.right = '-20%';
        option2.grid.left = '-1%';
        option2.yAxis.axisLabel = {
          ...option2.yAxis.axisLabel,
          textStyle: {
              color: 'white'
          }
        };
        // option2.series[0].color = colorPalette.slice(0, 17)
        option2.series[0].data = store.state.careerTestResults.graph_data.data.slice(0, 17);
        option2.yAxis.data = store.state.careerTestResults.graph_data.career_names.slice(0, 17);

        let instance1 = echarts.init(ctx1, null, {renderer: 'svg', width: 'auto', height: '600px'});
        instance1.setOption(option2);
        $(window).on('resize', function() {
          if (instance1 !== null && instance1 !== undefined) {
            instance1.resize();
          }
        });
      } else {
        // option.series[0].color = colorPalette
        option.series[0].data = store.state.careerTestResults.graph_data.data;
        option.yAxis.data = store.state.careerTestResults.graph_data.career_names;

        if (screen.availWidth < 800) {
          option.yAxis.axisLabel.fontSize = 10;
          option.series[0].label.fontSize = 8;
          option.series[0].label.position = 'right';
          option.grid.left = '-1%';
        } else {
          option.yAxis.axisLabel.fontSize = 15;
          option.series[0].label.fontSize = 12;
          option.series[0].label.position = 'inside';
          option.grid.left = '5%';
        }
        instance.setOption(option);
      }
      this.careerFitData = store.state.careerTestResults.career_detailed_info;
      this.$store.state.loadingScreen = false;
    },
    data: function() {
      return {
        loadingScreen: false,
        showPopover: false,
        popoverData: {},
        chartData: {
          responsive: true,
          maintainAspectRatio: true,
          'tooltip': {
            'show': false,
            'trigger': 'axis',
            'axisPointer': {
              'type': 'shadow',
            },
          },
          'legend': {
            'show': false,
          },
          'grid': {
            'right': '3%',
            'bottom': '0%',
            'containLabel': true,
          },
          'xAxis': {
            'type': 'value',
            'show': false,
            'boundaryGap': ['20%', '20%'],
          },
          'yAxis': {
            'type': 'category',
            'axisLabel': {
              'fontSize': 12,
            },
            'data': [],
          },
          'series': [
            {
              'name': '',
              'color': [],
              'label': {
                'show': true,
                'position': 'inside',
                'fontSize': 10,
                formatter: '€ {@score}',
              },
              'type': 'bar',
              'data': [],
            },
          ],
        },
        careerFitData: {},
      };
    },
    methods: {
      getChartMainTitle: function(sector) {
        let newList = [];
        sector.forEach(function(element) {
          newList.push('<strong>' + element + '</strong>');
        });
        return newList.join(' and ');
      },
      getImagePath: function(obj) {
        return `${this.publicPath}img/career_sectors/svg/${obj.name}.svg`;
      },
      closeDialog: function(value) {
        this.showPopover = value;
      }
      ,
      openPopover: function(data) {
        this.popoverData = data;
        this.showPopover = true;
      },
    },
  };
</script>

<style scoped>

    .my-chart{
      color: white !important;
    }

    .image {
        padding: 10px;
        height: 100px;
        /*background-color: lightblue;*/
    }

    .course-card-1 >>> .el-card__body {
        padding: 0 !important;
    }

    .course-card-1 {
      border-radius: 10px !important;
    }

    .index-class {
        position: absolute;
        margin-top: 1vh;
        margin-left: 1vh;
        font-style: normal;
        font-weight: 600;
        line-height: 2vw;
        align-items: center;
        color: #FFFFFF;
    }

    .course-card-1:hover {
        border: 1px solid lightgrey;
        cursor: pointer;
    }

    .main-class-1 {
        /*width: 100vw;*/
        overflow-x: auto;
        overflow-y: hidden;
        white-space: nowrap;
        margin: 0 0.5vw 0 0 !important;
    }

</style>