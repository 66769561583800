<template>
    <div>
        <div v-if="!isMobile()">
            <el-row style="display: flex; align-items: center; display: flex; background-color: #17484C; ">
                <el-col :span="22" class="component-title" v-if="!isMobile()">
                    Course Search
                </el-col>
                <el-col v-else>
                    &nbsp;
                </el-col>
                <el-col :span="2" v-if="!isMobile()">
                    <profile-avatar></profile-avatar>
                </el-col>
            </el-row>
        </div>
        <div>
            <!--            <SearchBar/>-->
            <CourseFilterDesktopSearch v-if="Object.keys(getFilters).length === 4" :filters="getFilters"/>
            <div class="scroll-container">
                <CollegeList/>
                <SuggestionList/>
            </div>
        </div>
    </div>
</template>


<script>
  import SearchBar from '../../page_components/CourseSearchComponents/SearchBar.vue';
  import CollegeList from '../../page_components/CourseSearchComponents/CollegeList.vue';
  import SuggestionList from '../../page_components/CourseSearchComponents/SuggestionList.vue';
  import ProfileAvatar from '../../main_components/ProfileAvatar';
  import CourseFilterDesktopSearch from '../../page_components/CourseFilterComponents/web/CourseFilterDesktopSearch.vue';

  export default {
    name: 'HomePage',
    mounted() {
      let self = this;
      self.sendAnalytics();
      self.$store.state.loadingScreen = true;
      self.checkUserLoggedIn().then(response => {
        self.fetchFiltersList().then(response => {
          self.filters = response;
        }).catch(error => {
        });
        self.$store.state.loadingScreen = false;
      }).catch(error => {
        error;
        self.$store.state.loadingScreen = false;
      });
    },
    data: function() {
      return {
        loadingScreen: false,
        filters: [],
      };
    },
    computed: {
      getFilters: function() {
        return this.filters;
      },
    },
    components: {
      CourseFilterDesktopSearch,
      ProfileAvatar,
      SearchBar,
      CollegeList,
      SuggestionList,
    },
  };
</script>


<style>
    .home-page-body {
        background: #f5f6fa;
    }

</style>
