<template>
    <el-dialog
            :destroy-on-close="true"
            :show-close="false" :visible.sync="dialogVisible" :close-on-click-modal="false"
            :close-on-press-escape="false" class="cookie-dialog">
        <div class="title-wrap" slot="title">
            <h3 v-if="!showCustomiseCookie">Cookie Consent</h3>
            <h3 v-else>
                <img @click="showCustomiseCookie=false" :src="`${publicPath}img/back.svg`" alt="no image"/>
                Choose your Cookie Preference
            </h3>
        </div>
        <div class="content-wrap" v-if="!showCustomiseCookie">
            <div class="msg-wrap">
                <el-row>We use cookies to improve your skillsvista experience and show personalised content,
                    depending on your settings. See our
                    <a style="color:#115cfa;"
                       target="_blank"
                       href="https://Skillsvista.com/privacy-policy/">Privacy Policy</a> for more.
                </el-row>
                <el-row class="btn-wrap">
                    <el-button class="secondary-color-font" @click="showCustomiseCookie=true">
                        Customise
                    </el-button>
                    <el-button class="secondary-bg-color whiteFont" @click="acceptAllCookies">
                        Accept
                    </el-button>
                </el-row>
            </div>
        </div>
        <div v-else class="cookie-checkbox">
            <el-row>
                <el-checkbox v-model="essentialCookies" checked disabled>Essential</el-checkbox>
                <div class="choice-desc">
                    Our website can’t operate properly without essential cookies.
                    We only collect the minimum personal information needed to provide services
                    on skillsvista.com. You can disable these cookies in your browser but you may not
                    be able to use skillsvista.com.
                </div>
            </el-row>
            <el-row>
                <el-checkbox v-model="nonEssentialCookies">Non Essential</el-checkbox>
                <div class="choice-desc">
                    We use marketing cookies to display personalised advertisements on other
                    sites you may visit. We work with approved partners to deliver relevant content and
                    measure the effectiveness of these advertisements
                </div>
            </el-row>
        </div>
    </el-dialog>
</template>

<script>
import BackArrow from "../../main_components/BackArrow";
import * as Cookies from "js-cookie";

export default {
    name: "CookieDialog",
    components: {BackArrow},
    props: ["dialogVisible"],
    data() {
        return {
            essentialCookies: true,
            nonEssentialCookies: false,
            showCustomiseCookie: false,
        };
    },
    methods: {
        acceptAllCookies() {
            Cookies.set("e_c_g", this.essentialCookies, { //cookie consent
                secure: true, // Change this to true before commit
                SameSite: "Strict",
            });
            Cookies.set("n_e_c_g", this.nonEssentialCookies, { //cookie consent
                secure: true, // Change this to true before commit
                SameSite: "Strict",
            });
            this.$emit("closeCookie");

        },
    }
};
</script>

<style scoped>
.btn-wrap {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 15px;
}

.btn-wrap button {
    margin-left: 10px;
    margin-right: 10px;
    padding: 10px 20px;
}

.msg-wrap {
    white-space: pre-wrap;
    text-align: justify;
    word-break: break-word;
    font-size: 14px;
}

.title-wrap {
    text-align: left;
}

.cookie-checkbox {
    text-align: left;
    display: block;
}

.cookie-checkbox >>> .el-checkbox__label {
    font-size: 16px;
    color: #17484C;
}

.cookie-checkbox .choice-desc {
    margin-bottom: 10px;
}

.cookie-checkbox >>> .el-checkbox__input.is-checked + .el-checkbox__label {
    color: #17484C;
    font-weight: 900;
}

.cookie-dialog >>> .el-dialog__body {
    padding-top: 10px;
}

.title-wrap h3 {
    margin-top: 0;
    margin-bottom: 0;
}

</style>