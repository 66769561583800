<template>
    <div>
        <el-row>
            <el-col :span="12" class="yooni-fit-head-1 head-1" style="color: white">
                Your Skillsvista Fit!
            </el-col>
        </el-row>
        <el-row>
            <el-col :span="18" class="yooni-fit-head-1 description-1" style="color: white">
                <div>
                    Complete the 3 steps below so our software can work its magic and find the perfect fitting courses
                    for you – your Skillsvista Fit courses to be precise!
                </div>
            </el-col>
        </el-row>
        <el-row :gutter="10" :style="{'padding': '10px'}">
            <el-col :key="index" :span="8" v-for="(obj, index) in yooniFitOptions">
                <el-card class="card">
                    <div class="step-class">
                        <svg fill="none" viewBox="0 0 50 50" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="25" cy="25" fill="#898989" r="23"/>
                            <text dominant-baseline="middle" fill="white" text-anchor="middle" x="50%" y="50%">
                                {{index + 1}}
                            </text>
                        </svg>
                    </div>
                    <img :src="`${publicPath}img/yooni_fit/${obj.image_url}.svg`" alt=""
                         class="image" style="background-color: white;">
                    <div class="card-content">
                        <el-row style="">
                            <div class="card-title">{{obj.title}}</div>
                        </el-row>
                        <el-row style="height: 8vh; display: flex; align-items: center;">
                            <div class="card-desc">
                                {{obj.desc}}
                            </div>
                        </el-row>
                    </div>
                    <el-row class="card-footer">
                        <el-button @click="$emit('slidePointsPredict')"
                                   class="mobile-signupButton findoutmore-button"
                                   type="button"
                                   v-if="obj.type==='points_predict' && !getPredictionsSubmitted">
                            {{obj.button_text}}
                        </el-button>
                        <el-button
                                @click="$emit('slidePointsPredict')"
                                class="mobile-signupButton findoutmore-button"
                                style="background: #2c3e50;"
                                type="success"
                                v-else-if="obj.type==='points_predict'">
                            Predicted CAO Points: <strong>{{$store.state.predictedCAOPoints}}</strong>
                        </el-button>
                        <button @click="showPaymentWall" class="black-primary-button yooni-fit-lock"
                                style="width: 100%;"
                                v-if="getUserLoggedIn && !getIsPremiumUser && obj.type !== 'points_predict'">
                            Sign Up
                        </button>
                        <el-button @click="$emit('slideCareerTest')"
                                   class="mobile-signupButton findoutmore-button"
                                   type="button"
                                   v-if="obj.type==='career_fit' && !getCareerFitCompleted && getIsPremiumUser">
                            {{obj.button_text}}
                        </el-button>
                        <el-button :disabled="true" class="mobile-signupButton findoutmore-button"
                                   icon="el-icon-check"
                                   style="background: #2c3e50; color: white !important;;"
                                   type="success"
                                   v-else-if="obj.type==='career_fit' && getCareerFitCompleted && getIsPremiumUser">
                            Careers Fit Completed
                        </el-button>
                        <el-button @click="$emit('slidePersonalityTest')"
                                   class="mobile-signupButton findoutmore-button"
                                   type="button"
                                   v-if="obj.type==='personality_fit' && !getInterestFitCompleted && getIsPremiumUser">
                            {{obj.button_text}}
                        </el-button>
                        <el-button :disabled="true" class="mobile-signupButton findoutmore-button"
                                   icon="el-icon-check"
                                   style="background: #2c3e50;color: white !important;"
                                   type="success"
                                   v-else-if="obj.type==='personality_fit' && getInterestFitCompleted && getIsPremiumUser">
                            Personality Fit Completed
                        </el-button>

                    </el-row>
                </el-card>
            </el-col>
        </el-row>
    </div>
</template>

<script>
  import yooniFitMixins from '../../mixins/yooni_fit_mixins';
  import {store} from '../../../store';

  export default {
    mixins: [yooniFitMixins],
    name: 'YooniFitHome',
    methods: {},
    mounted() {
      this.pointsPredicted = store.state.predictedCAOPoints !== false;
    },
    computed: {},
    data() {
      return {};
    },
  };
</script>

<style scoped>
    .card-title {
        height: 4vh;
        line-height: 4vh;
        font-family: Lato, serif;
        font-style: normal;
        font-weight: normal;
        font-size: 1vw;
        text-align: left;
        display: flex;
        align-items: center;
        color: #000000;
        padding: 0 1vh;
    }

    .card-desc {
        text-align: justify;
        padding: 0 1vh;
        font-size: 0.9vw;
    }

    .step-class {
        position: absolute;
        margin-left: 0.5vw;
        margin-top: 0.5vw;
        font-family: Lato, serif;
        font-style: normal;
        font-weight: 600;
        line-height: 2vw;
        align-items: center;
        color: #FFFFFF;
        width: calc(1.5vh + 1.5vw);
        font-size: calc(1.5vh + 0.5vw);
    }

    .head-1 {
        font-size: 1.3vw;
        line-height: 1.3vw;
        font-weight: 500;
    }


</style>
<style>
    .el-tabs__content {
        padding: 0 1vw;
    }

    .el-button {
        /*padding: 1.2vh 1vw !important;*/
    }

    .button {
        padding: 0;
        margin: 0 auto;
    }

    .image {
        width: 100%;
        padding: 1vh;
        height: 20vh;
        /*background-color: lightblue;*/
    }

    .clearfix:before,
    .clearfix:after {
        display: table;
        content: "";
    }

    .clearfix:after {
        clear: both
    }

    .card {
        background: white;
        border-radius: 10px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        overflow: hidden;
        display: flex;
        width: 25vw;
        flex-direction: column;
        justify-content: space-between;
        transition: transform 0.3s ease, box-shadow 0.3s ease;
    }

    .card:hover {
        transform: translateY(-10px);
        box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    }

    .card-content {
        padding: 20px;
        text-align: center;
    }

    .card-footer {
        padding: 20px;
        background-color: #ecf0f1;
        border-top: 1px solid #bdc3c7;
        text-align: center;
    }

</style>