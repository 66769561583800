<template>
    <div :style="!isMobile() ? {padding: '16px'} : {}">
        <el-dropdown trigger="click" style="padding: 10px; z-index: 999;">
            <div class="el-dropdown-link" style="display: flex; flex-direction: row;">
                <el-avatar class="profile-avatar" style="background:#377F7E;">
                    {{getNameInitial}}
                </el-avatar>
                <span style="" class="profile-down-arrow">
                    <i class="el-icon-arrow-down el-icon--right"></i>
                </span>
            </div>
            <el-dropdown-menu slot="dropdown">
                <el-dropdown-item @click.native="$router.push({path: '/login/', query: { redirect: $route.fullPath }})"
                                  v-if="!getUserLoggedIn">Log In
                </el-dropdown-item>
                <el-dropdown-item @click.native="$router.push({path: '/profile/'})"
                                  v-if="getUserLoggedIn">My Account
                </el-dropdown-item>
                <el-dropdown-item @click.native="logout()" v-if="getUserLoggedIn">Logout</el-dropdown-item>
            </el-dropdown-menu>
        </el-dropdown>
    </div>
</template>

<script>
  import * as Cookies from 'js-cookie';
  import {mapActions} from 'vuex';
  import LoadingScreen from './LoadingScreen';
  import $ from 'jquery';

  export default {
    name: 'ProfileAvatar',
    components: {LoadingScreen},
    data: function() {
      return {
        loadingScreen: false,
      };
    },
    computed: {
      getNameInitial: function() {
        if (this.$store.state.userType.toString() === '2')
          return 'C';
        else if (this.$store.state.isAdmin)
          return 'A';
        else if (!this.$store.state.userLoggedIn)
          return 'U';
        return this.$store.state.firstName.charAt(0).toUpperCase();
      },
    },
    methods: {
      ...mapActions(['setUserLoggedOut']),
      logout: function() {
        let self = this;
        self.$store.state.loadingScreen = true;
        let url = self.getBaseUrl() + '/accounts/logout/';
        $.ajax({
          url: url,
          type: 'GET',
          beforeSend: function(request) {
            request.setRequestHeader('Authorization',
                'Token ' + self.getAuthToken());
          },
          success: function(response) {
            self.$store.state.loadingScreen = false;
            Cookies.remove('authtoken');
            self.setUserLoggedOut();
            self.$store.state.showSidebar = true;
            self.setNonUserIntercom();
            // $('#intercom-frame').css('display', 'none');
            // $('#intercom-container').css('display', 'none');
            // $('.intercom-lightweight-app').css('display', 'none');
            // self.$router.push(self.$route.fullPath);
            self.$router.push('/');
          },
          error: function(error) {
            error;
            self.$store.state.loadingScreen = false;
          },
        });
      },
    },
  };
</script>

<style scoped>
    .profile-avatar {
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 900;
        font-size: calc(1.5vh + 0.5vw);
    }

    .profile-down-arrow {
        display: flex;
        align-items: center;
        justify-content: center;
    }
</style>
