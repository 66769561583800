<template xmlns:el-col="http://www.w3.org/1999/html">
    <div class="main-report" :style="isMobile() ? {padding: '16px'}:{}">
        <div style="background-color: #ffffff; border-radius: 7px; margin: 0 0 1vh; "
             :style="!isMobile() ? {padding: '0 1vw'}:{}">
            <el-row :gutter="10" class="main-row">
                <el-col :lg="5" :md="6" :sm="6" :xl="16" :xs="8">
                    <div class="about-you">
                        About You
                    </div>
                </el-col>
                <el-col :lg="8" :md="8" :sm="8" :xl="8" :xs="6">
                    <div class="grid-content"></div>
                </el-col>
                <el-col :lg="4" :md="6" :sm="6" :xl="4" :xs="7" style="padding: 1vh 0;" v-if="isMobile()">
                    <div class="grid-content bg-purple">
                        <div class="top-3">Top 3 Code: <strong>{{getInitials}}</strong></div>
                    </div>
                </el-col>
            </el-row>
            <el-row :gutter="10" class="main-row">
                <el-col :lg="12" :md="getSize()" :sm="24" :xl="14" :xs="24">
                    <div class="grid-content-1">
                        Take a look at your Personality Fit results! Remember, these results are based on the renowned
                        ‘Holland Occupational Themes’ methodology. Your top three Holland Codes are unique to you, so
                        use them to find courses and careers that match this uniqueness.
                    </div>
                </el-col>
                <el-col :lg="6" :md="6" :xl="5">
                    <el-col :key="index" :lg="24" :md="24" :sm="12" :xl="24" :xs="12"
                            v-for="(personality, index) in getPersonalities">
                        <div class="grid-content" :style="isMobile() ? {margin: '1vh 0'}: {'margin-bottom': '1vh'}">
                            <el-row class="main-row">
                                <el-col :xl="2" :lg="2" :md="3" :sm="4" :xs="4">
                                    <el-avatar
                                            :style="{'background-color': avatarColors[index]}"
                                            class="avatar-number">
                                        {{index + 1}}
                                    </el-avatar>
                                </el-col>
                                <el-col :lg="16" :md="19" :offset="1" :sm="18" :xl="16" :xs="18">
                                    <div class="personality-name">
                                        {{personality.name}} - {{personality.value}}%
                                    </div>
                                </el-col>
                            </el-row>
                        </div>
                    </el-col>
                </el-col>

                <el-col :lg="4" :md="4" :sm="4" :xl="3" :xs="4" v-if="!isMobile()">
                    <div class="grid-content bg-purple">
                        <div class="top-3">Top 3 Code: <strong>{{getInitials}}</strong></div>
                    </div>
                </el-col>
            </el-row>

            <el-row class="main-row">
                <el-col :xl="12" :lg="12" :md="getSize()" :sm="24" :xs="24">
                    <div style="width: 40vw; height: 33vh;" v-if="!isMobile()">
                        <div class="pie-chart" id="pie-chart"></div>
                    </div>
                    <div style="width: 100%; height: calc(35vh + 15vw); margin: auto;" v-else>
                        <div class="pie-chart-mobile" id="pie-chart"></div>
                    </div>
                </el-col>
                <div class="icon" v-if="!isMobile()">
                    <div class="arrow"></div>
                </div>
                <el-col :xl="12" :lg="12" :md="12" :sm="24" :style="!isMobile() ? {'margin-top': '3vh 0'}: {}" :xs="24"
                        v-if="showChartPopover" style="height: 30vh; display: flex; align-items: center;">
                    <el-card class="box-card popover-1">
                        <el-row style="margin: 1vh 0;">
                            <el-col :span="10" :offset="!isMobile() ? 1:0">
                                <div @click="$router.push({name: 'Personality'})" class="popoverTitle-interest"
                                     style="cursor: pointer;">
                                    {{popoverTitle}}
                                </div>
                            </el-col>
                            <el-col :offset="7" :span="6">
                                <div class="popoverPercent">
                                    {{popoverPercent}}% Fit
                                </div>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="10">
                                <div style="text-align: center !important;" v-if="!isMobile()">
                                    <img :fit="'contain'"
                                         :src="`${publicPath}img/personalities/svg/${getPopoverImage}-1.svg`"
                                         style="height: 7vw;">
                                </div>
                            </el-col>
                            <el-col :span="getSize()" class="popoverDesc">
                                {{componentArr[popoverTitle.toLowerCase()]}}
                            </el-col>
                        </el-row>
                    </el-card>
                </el-col>
            </el-row>

        </div>
        <div style="background-color: #ffffff; border-radius: 7px; margin: 0;"
             :style="!isMobile() ? {padding: '1vh 1vw'}:{}">
            <el-row class="main-row">
                <el-col :xl="10" :lg="10" :md="10" :sm="24" :xs="24">
                    <div class="avc-type-head">
                        Skillsvista users like you – <strong>{{getInitials}}</strong> types
                    </div>
                </el-col>
                <el-col v-if="!isMobile()" :xl="{span: 12, offset: 2}" :lg="{span: 12, offset: 2}"
                        :md="{span: 12, offset: 2}" :sm="24" :xs="24">
                    <div class="head-4">
                        Celebrities like you – <strong>{{getInitials}}</strong> types
                    </div>
                </el-col>
            </el-row>
            <el-row class="main-row">
                <el-col :xl="10" :lg="10" :md="10" :sm="24" :xs="24">
                    <el-row :key="index" class="yooni-population"
                            v-for="(population, index) in getPersonalities">
                        <el-col>
                            <el-col :lg="3" :md="3" :sm="3" :xl="3" :xs="3" class="avc-type-percent"
                                    style="display: flex; align-items: center;">
                                {{yooniPopulationPersonalities[population.name]}}%
                            </el-col>
                            <el-col :offset="1" :span="20" class="avc-type-desc">
                                Of our Skillsvista population have the <strong>{{population.name}}</strong> personality type
                            </el-col>
                        </el-col>
                    </el-row>
                </el-col>
                <el-col v-if="isMobile()" :xl="24" :lg="24" :md="24" :sm="24" :xs="24">

                </el-col>
                <el-col :xl="{span: 12, offset: 2}" :lg="{span: 12, offset: 2}" :md="{span: 12, offset: 2}" :sm="24"
                        :xs="24">
                    <el-row style="margin-top: 2vh">
                        <el-col v-if="isMobile()" :xl="24" :lg="24" :md="24" :sm="24" :xs="24">
                            <div class="head-4">
                                Celebrities like you – <strong>{{getInitials}}</strong> types
                            </div>
                        </el-col>
                    </el-row>
                    <el-row class="main-row" style="background-color: #f0f0f0; padding: 16px; height: auto;">
                        <el-col :key="index" :xl="8" :lg="8" :md="12" :sm="12" :xs="12"
                                v-for="(people, index) in getFamousPeople">
                            <el-avatar :fit="'fill'" :size="isMobile()?120:130"
                                       :src="`${publicPath}img/famous_people/${people}.jpg`"></el-avatar>
                            <!--                                    <el-image-->
                            <!--                                            :style="!isMobile() ? {'height': '8vw'}:{'height': 'calc(8vh + 8vw)', 'width': 'calc(8vh + 8vw)'}"-->
                            <!--                                            :src="`${publicPath}img/famous_people/${people}.jpg`"-->
                            <!--                                            :fit="'fill'"></el-image>-->
                            <div class="people-name">
                                {{people}}
                            </div>
                        </el-col>
                    </el-row>
                </el-col>
            </el-row>
        </div>

    </div>
</template>

<script>

  export default {
    name: 'PersonalityFitResult',
    computed: {
      getPopoverImage: function() {
        return this.popoverImage;
      },
      getInitials: function() {
        if (this.$store.state.personalityTestResults.initials !== undefined)
          return this.$store.state.personalityTestResults.initials.substr(0, 3);
        return '';
      },
      getPersonalities: function() {
        if (this.$store.state.personalityTestResults.test_score !== undefined) {
          return this.$store.state.personalityTestResults.test_score.filter(function(element, index) {
            if (index < 3) {
              return element;
            }
          });
        }
        return false;
      },
      getFamousPeople: function() {
        if (this.$store.state.personalityTestResults.famous_peoples !== undefined) {
          let endIndex;
          if (!this.isMobile())
            endIndex = 3;
          else
            endIndex = 2;
          return this.$store.state.personalityTestResults.famous_peoples.filter(function(element, index) {
            if (index < endIndex) {
              return element;
            }
          });
        }
        return '';
      },
    },
    mounted() {
      this.$store.state.loadingScreen = true;
      let seriesData = this.$store.state.personalityTestResults.graph_data;
      let echarts = require('echarts');
      let colorPalette = [
        '#ff0000',
        '#ffa500',
        '#ffff00',
        '#008000',
        '#0000ff',
        '#ee82ee',
        '#4b0082',
        '#66cc99',
      ];
      let option = {
        // backgroundColor: '#2c343c',
        title: {},
        tooltip: {
          show: false,
          trigger: 'item',
          formatter: '',
        },
        series: [
          {
            type: 'pie',
            name: 'Career Break Down',
            center: ['50%', '50%'],
            data: seriesData.sort(function(a, b) {
              return a.value - b.value;
            }),
            color: colorPalette,
            roseType: 'area',
            selectedMode: 'single',
            radius: ['0%', '90%'],
            // top: 5,
            labelLine: {
              show: false,
            },
            label: {
              show: false,
            },
            itemStyle: {
              borderColor: '#ffffff',
              borderWidth: 2,
              borderType: 'solid',
            },
            animationType: 'scale',
            animationEasing: 'elasticOut',
          },
        ],
        graph: {
          color: colorPalette,
        },
      };
      if (this.isMobile()) {
        option.series[0].center = ['60%', '50%'];
      } else {
        option.series[0].center = ['50%', '50%'];
      }
      let dom = document.getElementById('pie-chart');
      let instance = echarts.init(dom, null, {renderer: 'svg'});
      instance.setOption(option);
      let self = this;
      instance.on('mouseover', function(params) {
        self.showChartPopover = true;
        self.popoverTitle = params.data.name;
        self.popoverPercent = params.data.value;
        self.popoverImage = params.data.name.toLowerCase();
      });
      instance.on('mouseout', function() {
        // self.showChartPopover = false;
        // self.popoverTitle = params.data.name;
        // self.popoverPercent = params.data.value;
        // self.popoverImage = params.data.name.toLowerCase();
      });
      instance.on('click', function(params) {
        if (!self.isMobile())
          self.$router.push({name: 'Personality'});
      });
      self.popoverTitle = seriesData[0].name;
      self.popoverPercent = seriesData[0].value;
      self.popoverImage = seriesData[0].name.toLowerCase();
      self.$store.state.loadingScreen = false;

    },
    data: function() {
      return {
        loadingScreen: false,
        avatarColors: [
          '#428B98',
          '#C04075',
          '#53B0BE',
        ],
        popoverImage: '',
        popoverTitle: '',
        popoverPercent: '',
        showChartPopover: true,
        publicPath: process.env.BASE_URL,
        famousPeoples: [],
        personalities: [],
        componentArr: {
          'organiser': `People in the organiser category generally gravitate towards facts. Working with people and abstract ideas is less appealing than data and details. They are happy to double check facts and figures and are comfortable with a structured routine. They tend not to break the rules very often.`,
          'artistic': `Those in the Artistic category like to express themselves in whatever form works best for them. They enjoy projects that do not necessarily follow a strict set of rules and involve designs, forms and patterns. In school these people enjoy the likes of music or art.`,
          'enterprising': `Those in the enterprising tend to take control of group projects and lead the group. They tend to be a decision maker and sometimes take risks. These people graviate towards business and the start-up ecosystem.`,
          'thinker': `Thinkers enjoy solving problems with their minds and tend to think their way out of something. In school this population like the sciences, mathematics and any other subject that requires some form of technical analysis.`,
          'practical': `Practicalists enjoy working on real life problems that require some hands on work to find a solution. This generally involves working with materials and machinery, so some subjects like Construction, Engineering & DCG might appeal to this person. Many realist jobs require you to work outside.`,
          'helper': `People, people, people. Those in the Helper category often want to help others. Be it teaching, assisting or treating people, the are happy with providing a service to help others. `,
        },
        yooniPopulationPersonalities: {
          'Enterprising': 17,
          'Thinker': 19,
          'Artistic': 8,
          'Practical': 7,
          'Helper': 31,
          'Organiser': 8,
        },
      };
    },
    methods: {
      getImagePath: function(people) {
        return `${this.publicPath}img/famous_people/${people}.jpg`;
      },
      getSize: function() {
        if (this.isMobile() && screen.availWidth < 900) {
          return 24;
        } else {
          return 12;
        }
      },
    },
  };
</script>

<style>
    .el-card__body {
    }

    .bg-purple {
        background: #59A3CC;;
    }

    .el-avatar > img {
        width: 100%;
    }

</style>

<style scoped>
    .icon {
        position: absolute;
        top: 50%;
        left: 42%;
        transform: translate(-50%, -50%);
        width: 80px;
        height: 60px;
    }

    .arrow {
        position: absolute;
        top: 25px;
        width: 70%;
        height: 10px;
        background-color: rgb(153, 204, 255);
        box-shadow: 0 3px 5px rgba(0, 0, 0, .2);
        animation: arrow 1200ms linear infinite;
    }

    @keyframes arrow {
        0% {
            left: 0;
        }
        50% {
            left: 10px;
        }
        100% {
            left: 0;
        }
    }

    .arrow::after, .arrow::before {
        content: '';
        position: absolute;
        width: 75%;
        height: 10px;
        left: -8px;
        background-color: rgb(153, 204, 255);
    }

    .arrow::after {
        top: -12px;
        transform: rotate(135deg);
    }

    .arrow::before {
        top: 12px;
        box-shadow: 0 3px 5px rgba(0, 0, 0, .2);
        transform: rotate(-135deg);
    }

    .grid-content {
        border-radius: 4px;
        min-height: 1vw;
    }

    .about-you {
        font-family: 'Niramit', serif !important;
        font-style: normal;
        font-weight: 300;
        color: #000000;
        text-align: left;
    }


    .popoverDesc {
        height: auto;
        color: #ffffff;
        font-family: Lato, serif;
        font-style: normal;
        font-weight: 300;
        text-align: justify;
    }

    .popover-1 {
        background: #428B98;
        margin: auto;
        text-align: justify;
        font-family: 'Niramit', serif !important;
        font-style: normal;
        color: #ffffff;
        font-weight: 300;
        padding: 16px;
        height: auto;
    }

    .avc-type-head {
        font-family: 'Niramit', serif !important;;
        font-style: normal;
        font-weight: 500;
        color: #000000;
        text-align: justify;
    }

    .avc-type-percent {
        font-family: Lato, serif;
        font-style: normal;
        font-weight: 500;
        color: #000000;
        text-align: center;
    }


    .head-4 {
        font-family: 'Niramit', serif !important;
        font-style: normal;
        font-weight: 300;
        color: #000000;
        text-align: justify;
    }


    .people-name {
        font-family: Lato, serif;
        font-style: normal;
        font-weight: 500;
        align-items: center;
        color: #000000;
    }


    .main-row {
        margin-top: 0.1vh;
    }

    .pie-chart {
        height: 35vh !important;
        width: 40vw !important;
        margin: 0 auto !important;
    }

    .pie-chart-mobile {
        height: calc(35vh + 15vw);
        width: 80vw !important;
        margin: 0 auto !important;
        padding: 2vh 0;
    }

</style>
