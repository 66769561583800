<template>
    <el-card class="accommodation">
        <div class="courseTopicTitle">Course Location</div>
        <div id="map"></div>
        <br>
        <div class="courseTopicTitle">Accommodation</div>
        <div v-for="(acc, index) in accommodation" :key="index" style="text-align: left;">
            <div>{{acc.name}}: <a :href="acc.website">{{acc.website}}</a></div>
        </div>

    </el-card>
</template>

<style scoped>
</style>
<script>

  export default {
    name: 'Accommodation',
    props: ['latitude', 'longitude', 'collegeName'],
    data: function() {
      return {
        accommodation: [
          {
            name: 'Daft.ie',
            website: 'https://www.daft.ie/student-accommodation/',
          }],
      };
    },
    async mounted() {
      initMap(this.latitude, this.longitude, this.collegeName);
    },
    methods: {},
  };
</script>
<style scoped>
    #map {
        height: 300px;
    }

    .accommodation {
        margin: 3vh 1vw;
        box-shadow: 0 0 0 0;
        background: #ffffff;
        border-radius: 4px;
        padding: 2vh 3vw;
    }
</style>