<template>
    <div>
        <div class="course-filter-search">
            <!--            <el-row>-->
            <!--                <el-col :xl="Object.keys(filters).length > 0 ? 12: 21" :lg="Object.keys(filters).length > 0 ? 12: 21"-->
            <!--                        :md="Object.keys(filters).length > 0 ? 12: 21" :sm="24" :xs="24">-->
            <!--                    &lt;!&ndash;                    <div class="top-labels">Search by Course Name/Code/Sector/College</div>&ndash;&gt;-->
            <!--                </el-col>-->
            <!--            </el-row>-->
            <el-row :gutter="10">
                <el-col :xl="Object.keys(filters).length > 0 ? 12: 18" :lg="Object.keys(filters).length > 0 ? 12: 18"
                        :md="Object.keys(filters).length > 0 ? 12: 18" :sm="Object.keys(filters).length > 0 ? 24: 18"
                        :xs="Object.keys(filters).length > 0 ? 24: 18">
                    <!--                    <el-autocomplete-->
                    <!--                            placeholder="Search by Course Name/Code/Sector/College"-->
                    <!--                            clearable-->
                    <!--                            @change="onSubmit"-->
                    <!--                            @keyup.native.enter="onSubmit"-->
                    <!--                            @select="onSubmit"-->
                    <!--                            class="el-input search-input main-search"-->
                    <!--                            v-model="courseName"-->
                    <!--                            :fetch-suggestions="queryCourseSearchAsync"-->
                    <!--                    ></el-autocomplete>-->
                    <el-input
                            placeholder="Search by Course Name/Code/Sector/College"
                            clearable
                            @change="onSubmit"
                            @keyup.native.enter="onSubmit"
                            @select="onSubmit"
                            class="el-input search-input main-search"
                            v-model="courseName"
                    ></el-input>
                </el-col>
                <el-col :xl="Object.keys(filters).length > 0 ? 3: 6" :lg="Object.keys(filters).length > 0 ? 3: 6"
                        :md="Object.keys(filters).length > 0 ? 3: 6" :sm="Object.keys(filters).length > 0 ? 3: 6"
                        :xs="Object.keys(filters).length > 0 ? 3: 6"
                        v-if="!isMobile() || Object.keys(filters).length === 0">
                    <el-button @click="onSubmit" class="search-btn" size="medium">Search</el-button>
                </el-col>

                <el-col :xl="{span: 3, offset: 5}" :lg="{span: 3, offset: 5}" :md="{span: 3, offset: 5}"
                        v-if="!isMobile() && Object.keys(filters).length > 0">
                    <el-popover
                            :width="'100%'"
                            placement="bottom-end"
                            visible-arrow="false">
                        <el-row :gutter="20">
                            <el-col :span="8">
                                <el-row :gutter="20">
                                    <el-col :span="8">
                                        <div class="filterLabel">College Name</div>
                                    </el-col>
                                    <el-col :span="16">
                                        <el-autocomplete
                                                clearable
                                                @select="onSubmit"
                                                class="el-input search-input"
                                                v-model="collegeName"
                                                :fetch-suggestions="queryCollegeSearchAsync">
                                        </el-autocomplete>
                                    </el-col>
                                </el-row>
                            </el-col>
                            <el-col :span="8">
                                <el-row :gutter="20">
                                    <el-col :span="8">
                                        <div class="filterLabel">Career Sector</div>
                                    </el-col>
                                    <el-col :span="16">
                                        <el-autocomplete
                                                clearable
                                                @select="onSubmit"
                                                class="el-input search-input"
                                                v-model="sectorName"
                                                :fetch-suggestions="querySectorSearchAsync">
                                        </el-autocomplete>
                                        <!--                                        <el-select @change="onSubmit" style="width: 100%;" v-model="sectorName">-->
                                        <!--                                            <el-option-->
                                        <!--                                                    :key="sector"-->
                                        <!--                                                    v-bind:label="sector"-->
                                        <!--                                                    v-bind:value="sector"-->
                                        <!--                                                    v-for="sector in getSectorArr"-->
                                        <!--                                            ></el-option>-->
                                        <!--                                        </el-select>-->
                                    </el-col>
                                </el-row>
                            </el-col>
                            <el-col :span="8">
                                <el-row :gutter="20">
                                    <el-col :span="8" style="height: 40px;">
                                        <div class="filterLabel">Province</div>
                                    </el-col>
                                    <el-col :span="16">
                                        <el-select clearable @change="onSubmit" style="width: 100%;"
                                                   v-model="provinceName">
                                            <el-option
                                                    :key="province"
                                                    v-bind:label="province"
                                                    v-bind:value="province"
                                                    v-for="province in provinceArr"
                                            ></el-option>
                                        </el-select>
                                    </el-col>
                                </el-row>
                            </el-col>
                        </el-row>
                        <el-row :gutter="20" style="margin-top: 2vh;">
                            <el-col :span="8">
                                <el-row :gutter="20">
                                    <el-col :span="8" style="height: 40px;">
                                        <div class="filterLabel">Level</div>
                                    </el-col>
                                    <el-col :span="16">
                                        <el-select @clear="onSubmit" @change="onSubmit" clearable style="width: 100%;"
                                                   v-model="courseLevel">
                                            <el-option
                                                    :key="level"
                                                    v-bind:label="level"
                                                    v-bind:value="level"
                                                    v-for="level in levelArr"
                                            ></el-option>
                                        </el-select>
                                    </el-col>
                                </el-row>
                            </el-col>

                            <el-col :span="3">
                                <el-row :gutter="20" v-if="$store.state.searchedCourses.length !== 0">
                                    <el-col :span="21" style="height: 40px;">
                                        <div class="filterLabel">Points Range</div>
                                    </el-col>
                                </el-row>
                            </el-col>
                            <el-col :span="12">
                                <el-row :gutter="16" v-if="$store.state.searchedCourses.length !== 0">
                                    <el-col :span="1">
                                        <label class="minMaxIndicators">
                                            {{caoFilterRange[0] }}
                                        </label>
                                    </el-col>
                                    <el-col :span="12">
                                        <el-slider
                                                :input-size="'large'"
                                                :max="625"
                                                @change="updateCAO"
                                                range
                                                v-model="caoFilterRange">
                                        </el-slider>
                                    </el-col>
                                    <el-col :span="2">
                                        <label class="minMaxIndicators">
                                            {{caoFilterRange[1] }}
                                        </label>
                                    </el-col>
                                </el-row>
                            </el-col>
                            <!--                            <el-col v-if="!getIsPremiumUser" :lg="{span: 8, offset: 2}" :md="{span: 8, offset: 2}"-->
                            <!--                                    :sm="{span: 20, offset: 2}"-->
                            <!--                                    :xl="{span: 8, offset: 2}" :xs="{span: 20, offset: 2}" class="yooni-fit-text">-->
                            <!--                                <el-row>-->
                            <!--                                    <el-col style="text-align: center;">-->
                            <!--                                        Find your perfect course with Yooni-->
                            <!--                                    </el-col>-->
                            <!--                                </el-row>-->
                            <!--                                <br>-->
                            <!--                                <el-row v-if="!$store.state.isPremiumUser">-->
                            <!--                                    <el-col :offset="4" :span="16">-->
                            <!--                                        <el-button @click="paymentDialog=true" style="width: 100%;">Unlock Yooni Fit-->
                            <!--                                        </el-button>-->
                            <!--                                    </el-col>-->
                            <!--                                </el-row>-->
                            <!--                            </el-col>-->
                        </el-row>
                        <el-button class="filter-btn" slot="reference">
                            <span class="filter-btn-text">Filters</span>
                            <span class="filter-btn-icon">
                                <i class="el-icon-arrow-down"></i>
                            </span>
                        </el-button>
                    </el-popover>
                </el-col>
            </el-row>
        </div>
        <el-row v-if="isMobile() && Object.keys(filters).length > 0">
            <el-col :span="$store.state.searchedCourses.length !== 0 ? 12 : 24">
                <el-popover
                        class="filterPopoverMobile"
                        placement="bottom-end"
                        style="width: 100%;"
                        visible-arrow="false">

                    <el-row :gutter="20">
                        <el-col :lg="8" :md="24" :sm="24" :xl="8" :xs="24">
                            <el-row>
                                <el-col :span="6">
                                    <div class="filterLabel">College Name</div>
                                </el-col>
                                <el-col :span="18">
                                    <el-autocomplete
                                            clearable
                                            @change="onSubmit"
                                            @keyup.native.enter="onSubmit"
                                            @select="onSubmit"
                                            class="el-input search-input"
                                            v-model="collegeName"
                                            :fetch-suggestions="queryCollegeSearchAsync">
                                    </el-autocomplete>
                                </el-col>
                            </el-row>
                        </el-col>
                        <el-col :lg="8" :md="24" :sm="24" :xl="8" :xs="24">
                            <el-row>
                                <el-col :span="6">
                                    <div class="filterLabel">Career Sector</div>
                                </el-col>
                                <el-col :span="18">
                                    <el-autocomplete
                                            clearable
                                            @change="onSubmit"
                                            @keyup.native.enter="onSubmit"
                                            @select="onSubmit"
                                            class="el-input search-input"
                                            v-model="sectorName"
                                            :fetch-suggestions="querySectorSearchAsync">
                                    </el-autocomplete>
                                </el-col>
                            </el-row>
                        </el-col>
                        <el-col :xl="6" :lg="6" :md="6" :sm="24" :style="isMobile() ? {marginTop: '10px'} : {}"
                                :xs="24">
                            <el-row>
                                <el-col :span="6">
                                    <div class="filterLabel">Province</div>
                                </el-col>
                                <el-col :span="18">
                                    <el-select clearable @change="onSubmit" style="width: 100%;" v-model="provinceName">
                                        <el-option
                                                :key="province"
                                                v-bind:label="province"
                                                v-bind:value="province"
                                                v-for="province in provinceArr"
                                        ></el-option>
                                    </el-select>
                                </el-col>
                            </el-row>
                        </el-col>
                        <el-col :xl="6" :lg="6" :md="6" :sm="24" :style="isMobile() ? {marginTop: '10px'} : {}"
                                :xs="24">
                            <el-row>
                                <el-col :span="6">
                                    <div class="filterLabel">Level</div>
                                </el-col>
                                <el-col :span="18">
                                    <el-select clearable @clear="onSubmit" @change="onSubmit" style="width: 100%;"
                                               v-model="courseLevel">
                                        <el-option
                                                :key="level"
                                                v-bind:label="level"
                                                v-bind:value="level"
                                                v-for="level in levelArr"
                                        ></el-option>
                                    </el-select>
                                </el-col>
                            </el-row>
                        </el-col>
                    </el-row>
                    <el-row :style="isMobile() ? {marginTop: '10px'} : {marginTop: '10px'}">
                        <el-col :xl="6" :lg="6" :md="6" :sm="24" :xs="24">
                            <el-row v-if="$store.state.searchedCourses.length !== 0">
                                <el-col :span="6">
                                    <div class="filterLabel">Points Range</div>
                                </el-col>
                                <el-col :span="2">
                                    <label class="minMaxIndicators">
                                        {{caoFilterRange[0] }}
                                    </label>
                                </el-col>
                                <el-col :span="14" style="">
                                    <el-slider
                                            :input-size="'mini'"
                                            :max="625"
                                            @change="updateCAO"
                                            range
                                            show-stops
                                            v-model="caoFilterRange">
                                    </el-slider>
                                </el-col>
                                <el-col :span="2">
                                    <label class="minMaxIndicators">
                                        {{caoFilterRange[1] }}
                                    </label>
                                </el-col>
                            </el-row>
                        </el-col>
                    </el-row>
                    <!--                    <el-row v-if="!getIsPremiumUser">-->
                    <!--                        <el-col :lg="{span: 8, offset: 8}" :md="{span: 20, offset: 2}" :sm="{span: 20, offset: 2}"-->
                    <!--                                :xl="{span: 8, offset: 8}" :xs="{span: 20, offset: 2}" class="yooni-fit-text">-->
                    <!--                            <el-row>-->
                    <!--                                <el-col style="text-align: center;">-->
                    <!--                                    Find your perfect course with Yooni-->
                    <!--                                </el-col>-->
                    <!--                            </el-row>-->
                    <!--                            <br>-->
                    <!--                            <el-row>-->
                    <!--                                <el-col :offset="2" :span="20">-->
                    <!--                                    <el-button @click="paymentDialog=true" style="width: 100%;">-->
                    <!--                                        Unlock Yooni Fit-->
                    <!--                                    </el-button>-->
                    <!--                                </el-col>-->
                    <!--                            </el-row>-->

                    <!--                        </el-col>-->
                    <!--                    </el-row>-->
                    <el-button class="filter-btn" slot="reference"
                               style="width: 100% !important; border-radius: 0 !important; box-shadow: none; padding: 1.2vh 1vw !important;">
                        <span class="filter-btn-text">Filters</span>
                        <span class="filter-btn-icon">
                                <i class="el-icon-arrow-down"></i>
                            </span>
                    </el-button>
                </el-popover>
            </el-col>
            <el-col :sm="{span: 12}" :xs="{span: 12}" v-if="$store.state.searchedCourses.length !== 0">
                <el-dropdown style="width: 100% !important;" placement="bottom-end" @command="handleDropdownRequest">
                    <el-button
                            class="el-dropdown-link"
                            style="width: 100% !important; border-radius: 0 !important; padding: 1.4vh 1vw !important;">
                        <div class="sort-by"><strong>Sort by:</strong>{{activeSortType}}</div>
                    </el-button>
                    <el-dropdown-menu slot="dropdown" class="dropdown">
                        <el-dropdown-item
                                v-for="el in dropdownElements"
                                :key="el.name"
                                v-bind:command="el.name">{{el.name}}
                        </el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
            </el-col>
        </el-row>
        <el-row :gutter="20" v-if="!isMobile() && getFilters.length > 0">
            <el-col :span="24" class="filter-section">
                <el-tag class="filter-button"
                        style="margin-right: 10px;"
                        :key="tag.name"
                        :type="''"
                        @close="removeFilter(tag)"
                        closable
                        effect="dark"
                        v-for="tag in getFilters">
                    {{tag.name}}: <strong>{{tag.value}}</strong>
                </el-tag>
                <el-button class="clearall" @click="clearFilters" style="margin-left: 16px;"
                           type="text">
                    Clear All
                </el-button>
            </el-col>
        </el-row>
        <payment-popover :status="paymentDialog" @close="closePaymentDialog" v-if="paymentDialog"></payment-popover>
    </div>
</template>


<script>
  import {mapActions} from 'vuex';
  import PaymentPopover from '../../../main_components/PaymentPopover';

  export default {
    name: 'CourseFilterDesktopSearch',
    props: ['filters'],
    components: {PaymentPopover},
    data() {
      return {
        loadingScreen: false,
        paymentDialog: false,
        dropdownElements: [
          {
            name: 'Default',
          },
          {
            name: 'Course Name(A-Z)',
          },
          {
            name: 'Course Name(Z-A)',
          },
          {
            name: 'CAO Points(Low-High)',
          },
          {
            name: 'CAO Points(High-Low)',
          },
        ],
        activeSortType: 'Default',
        listOfFilters: [],
        labelPosition: 'top',
        location: '',
        courseName: '',
        provinceName: '',
        collegeName: '',
        sectorName: '',
        province: '',
        career_sector: '',
        college: '',
        county: '',
        caoFilterRange: [0, 625],
        filterData: {
          careers: [
            {
              name: 'Engineering',
            },
            {
              name: 'Art',
            },
          ],
        },
        coursesArr: [],
        provinceArr: ['All', 'Dublin', 'Connact', 'Leinster', 'Munster', 'Ulster'],
        levelArr: ['All', '6', '7', '8'],
        collegeArr: [],
        sectorArr: [],
        courseLevel: '',
      };
    },
    ...mapActions([
      'setSearchedCourseName',
      'setSearchedCollegeName',
      'setSearchedProvinceName',
      'setSearchedSectorName',
      'setSearchedLevel',
    ]),
    mounted() {
      this.getSelectedFilters(true);
      let self = this;
      this.courseFilters = [];
      const queryString = self.$route.query;
      if (self.filters['courses'] !== undefined)
        self.coursesArr = self.filters['courses'];
      // self.provinceArr = self.filters['provinces'];
      if (self.filters['colleges'] !== undefined)
        self.collegeArr = self.filters['colleges'];
      if (self.filters['sectors'] !== undefined)
        self.sectorArr = self.filters['sectors'];
      if (queryString['mn'] !== undefined)
        self.caoFilterRange[0] = queryString['mn'];
      if (queryString['mx'] !== undefined)
        self.caoFilterRange[1] = queryString['mx'];
      self.$store.state.loadingScreen = false;
      self.$emit('updateCourseOnCAO', self.caoFilterRange);

      if (queryString['course'] !== undefined) {
        self.courseName = queryString['course'];
      }
      if (queryString['college'] !== undefined) {
        self.collegeName = queryString['college'];
      }
      if (queryString['province'] !== undefined) {
        self.provinceName = queryString['province'];
      }
      if (queryString['sector'] !== undefined) {
        self.sectorName = queryString['sector'];
      }
      if (queryString['level'] !== undefined) {
        self.courseLevel = queryString['level'];
      }
      self.setSearchedCourseName(self.courseName);
      self.setSearchedCollegeName(self.collegeName);
      self.setSearchedProvinceName(self.provinceName);
      self.setSearchedSectorName(self.sectorName);
      self.setSearchedLevel(self.courseLevel);
    },
    computed: {
      getSectorArr: function() {
        return this.sectorArr;
      },
      getMarks: function() {
        let min = this.caoFilterRange[0];
        let max = this.caoFilterRange[1];
        return {min: min, max: max};
      },
      getFilters: function() {
        return this.courseFilters;
      },
    },
    methods: {
      ...mapActions([
        'setSearchedCourseName',
        'setSearchedCollegeName',
        'setSearchedProvinceName',
        'setSearchedSectorName',
        'setSearchedLevel',
        'setSearchedCourses',
      ]),
      closePaymentDialog: function() {
        this.paymentDialog = false;
      },
      updateCAO: function() {
        // this.$emit('updateCourseOnCAO', this.caoFilterRange);
        this.onSubmit();
      },
      removeFilter: function(tag) {
        const queryString = JSON.parse(JSON.stringify(this.$route.query));
        let self = this;
        if (tag.name === 'College Name') {
          delete queryString.college;
          self.setSearchedCollegeName('');
        }
        if (tag.name === 'Course Name') {
          delete queryString.course;
          self.setSearchedCourseName('');
        }
        if (tag.name === 'Province Name') {
          delete queryString.province;
          self.setSearchedProvinceName('');
        }
        if (tag.name === 'Sector Name') {
          delete queryString.sector;
          self.setSearchedSectorName('');
        }
        if (tag.name === 'Level') {
          delete queryString.level;
          self.setSearchedLevel('');
        }
        self.courseFilters = self.courseFilters.filter(function(element) {
          return element !== tag;
        });
        this.setSearchedCourses([]);
        this.$nextTick(function() {
          this.$router.push({path: '/filter', query: queryString});
        });
      },
      getSearchFilterResults: function(programList, queryString, cb) {
        let results = queryString
            ? programList.filter(this.createStateFilter(queryString))
            : programList;
        //The basic idea is to implement a filter that filters all the data that exists in your input field.
        clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          cb(results);
        }, 1000 * Math.random());
      },
      querySectorSearchAsync: function(queryString, cb) {
        let programList = [];
        for (let i = 0; i < this.sectorArr.length; i++) {
          programList.push({
            value: this.sectorArr[i],
          });
        }
        this.getSearchFilterResults(programList, queryString, cb);
      },
      queryCollegeSearchAsync: function(queryString, cb) {
        let programList = [];
        for (let i = 0; i < this.collegeArr.length; i++) {
          programList.push({
            value: this.collegeArr[i],
          });
        }
        this.getSearchFilterResults(programList, queryString, cb);
      },
      queryCourseSearchAsync(queryString, cb) {
        let programList = [];
        for (let i = 0; i < this.coursesArr.length; i++) {
          programList.push({
            value: this.coursesArr[i],
          });
        }
        this.getSearchFilterResults(programList, queryString, cb);
      },

      createStateFilter(queryString) {
        return (program) => {
          return (
              program.value.toLowerCase().indexOf(queryString.toLowerCase()) !== -1
          );
        };
      },
      clearFilters: function() {
        // this.courseName = '';
        this.collegeName = '';
        this.sectorName = '';
        this.provinceName = '';
        let query = {}
        if (this.collegeName !== '')
          query = {course: this.courseName}
        this.$router.push({path: '/filter', query: query});
        this.onSubmit();
      },
      onSubmit: function() {
        let requestQuery = [];
        let courseName = this.courseName;
        let collegeName = this.collegeName;
        let sectorName = this.sectorName;
        let provinceName = this.provinceName;
        let level = this.courseLevel;
        let caoMin = this.caoFilterRange[0];
        let caoMax = this.caoFilterRange[1];

        this.setSearchedCourseName(this.courseName);
        this.setSearchedCollegeName(this.collegeName);
        this.setSearchedSectorName(this.sectorName);
        this.setSearchedProvinceName(this.provinceName);
        this.setSearchedLevel(this.courseLevel);

        if (courseName !== undefined && courseName !== '') {
          // this.sendAnalyticsEvents('course_search', courseName);
          requestQuery.push('course=' + courseName);
        }
        if (collegeName !== undefined && collegeName !== '') {
          // this.sendAnalyticsEvents('college_search', collegeName);
          requestQuery.push('college=' + collegeName);
        }
        if (provinceName !== undefined && provinceName !== '') {
          // this.sendAnalyticsEvents('province_search', provinceName);
          requestQuery.push('province=' + provinceName);
        }
        if (sectorName !== undefined && sectorName !== '') {
          // this.sendAnalyticsEvents('sector_search', sectorName);
          requestQuery.push('sector=' + sectorName);
        }
        if (level !== undefined && level !== '') {
          // this.sendAnalyticsEvents('level_search', level);
          requestQuery.push('level=' + level);
        }
        this.setSearchedCourses([]);
        if (requestQuery.length !== 0) {
          requestQuery.push('mn=' + caoMin);
          requestQuery.push('mx=' + caoMax);
          this.$store.state.currentPageNumber = 1;
          this.$store.state.totalSearchCount = 0;
          this.$router.push('/filter?' + requestQuery.join('&'));
        }
      },
      handleDropdownRequest: function(command) {
        let self = this;
        self.activeSortType = command;
        self.setSortType(command);
        self.fetchCourses().then(response => {
          setTimeout(function() {
            self.$store.state.loadingScreen = false;
          }, 2000);
        }).catch(error => {
          setTimeout(function() {
            self.$store.state.loadingScreen = false;
          }, 2000);
        });
      },
    },
  };
</script>

<style>
    .el-slider__stop {
        background-color: #d8d8d8 !important;
    }

    .el-tag {
        font-size: 0.8vw !important;
    }
</style>
<style scoped>

    .filter-section {
        display: flex;
        align-items: center;
        margin-left: 16px;
        margin-top: 1.5vh;
        margin-bottom: 1.5vh;
    }

    .filter-button {
        background: #E7EDEB !important;
        border: 1px ;
        color: #17484C !important;
    }

    .filter-btn:hover {
        background: #ffffff !important;
        border: 1px ;
        color: #7D90D0 !important;
    }

    .filter-btn-text {
        float: left;
        font-family: Lato, serif;
        font-style: normal;
        font-weight: 500;
        color: #000000;
    }

    .filter-btn-icon {
        float: right;
    }

    .search-text >>> .el-form-item__label {
        visibility: hidden;
        padding: 0;
        margin: 0;
        text-align: left;
        font-family: Niramit, serif !important;
        font-style: normal;
        font-weight: 300;
        font-size: 14px;
        line-height: 18px;
        color: #ffffff;
    }

    .filter >>> .el-form-item__label {
        visibility: hidden;
        padding: 0;
        margin: 0;
        text-align: left;
        font-family: Niramit, serif !important;
        font-style: normal;
        font-weight: 300;
        font-size: 14px;
        line-height: 18px;
        color: #ffffff;
    }

    .top-labels {
        padding-bottom: 1vh;
        margin: 0;
        float: left;
        font-family: Niramit, serif !important;
        font-style: normal;
        font-weight: 500;
        color: #ffffff;
    }

    .location-search >>> .el-form-item__label {
        padding: 0;
        margin: 0;
        float: left;
        font-family: Niramit, serif !important;
        font-style: normal;
        font-weight: 300;
        font-size: 14px;
        line-height: 18px;
        color: #ffffff;
    }

    .search-text.el-form-item {
        width: 20%;
        margin-bottom: 1%;
        padding-left: 1%;
    }

    .search-text >>> .el-form-item__content {
        display: block;
        width: 1%;
    }

    .search-input {
        font-size: 1vw;
        line-height: 1vw;
    }

    .search-input.main-search {
        /*padding-left: 1vw;*/
        /*padding-right: 1vw;*/
        width: 100%;
    }

    .search-btn {
        background: #17484C;
        border: 1px ;
        border-radius: 5px;
        padding-top: 0 !important;
        padding-bottom: 0 !important;
        height: 40px;
        font-style: normal;
        font-weight: 600;
        line-height: 40px;
        text-align: center;
        color: white;
        width: 100%;
    }

    .search-btn:hover {
        background: #2c3e50 !important;
        border: 1px;
        color: #ffffff !important;
    }

    .yooni-fit-text {
        letter-spacing: -1px;
        color: #FFFFFF;
        font-family: Lato, serif;
        font-style: normal;
        font-weight: 600;
        word-break: break-word;
        text-align: left;
    }

    .clearall {
        padding: 8px 0 !important;
        color: white
    }
</style>
