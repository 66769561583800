<template>
    <el-row>
        <el-dialog
                :close-on-click-modal="false"
                :close-on-press-escape="false"
                :modalAppendToBody="false"
                :show-close="false"
                :visible.sync="dialogVisible"
                :width="!isMobile() ? '45%': '95%'">

            <el-steps :active="activeStep" align-center id="personalInfoId">
                <el-step title="School Information" icon="el-icon-school"></el-step>
                <el-step title="Favourite College" icon="el-icon-guide"></el-step>
                <el-step title="Counsellor" icon="el-icon-s-custom"></el-step>
                <el-step title="Final" icon="el-icon-s-promotion"></el-step>
            </el-steps>
            <el-row style="padding: 1vh 2vw;">
                <el-row ref="'step-1" v-if="activeStep===0">
                    <el-row style="margin: 1vh 0;">
                        <div class="school-college-label">Select your secondary school</div>
                    </el-row>
                    <el-row style="margin: 1vh 0;">
                        <el-select
                                filterable placeholder="Select School" allow-create style="width: 80%; float: left;"
                                v-model="schoolName">
                            <el-option
                                    :key="index"
                                    :label="item.value"
                                    :value="item.value"
                                    v-for="(item, index) in schools">
                            </el-option>
                        </el-select>
                    </el-row>

                    <el-row v-if="schoolName !== ''">
                        <el-row style="margin: 1vh 0;">
                            <div class="school-college-label">What year are you in?</div>
                        </el-row>
                        <el-row style="margin: 1vh 0;">
                            <el-select @change="activeStep=activeStep+1" filterable placeholder="Select Level"
                                       allow-create
                                       style="width: 80%; float: left;"
                                       v-model="currentYear">
                                <el-option
                                        :key="index"
                                        :label="item.label"
                                        :value="item.value"
                                        v-for="(item, index) in schoolYears">
                                </el-option>
                            </el-select>
                        </el-row>
                    </el-row>
                </el-row>
                <el-row ref="step-2" v-else-if="activeStep===1">
                    <el-row style="margin: 1vh 0;">
                        <div class="school-college-label">What is your preferred college?</div>
                    </el-row>
                    <el-row style="margin: 1vh 0;">
                        <el-select @change="activeStep=activeStep+1" filterable placeholder="Select College"
                                   style="width: 100%"
                                   v-model="collegeName">
                            <el-option
                                    :key="index"
                                    :label="item.value"
                                    :value="item.value"
                                    v-for="(item, index) in colleges">
                            </el-option>
                        </el-select>
                    </el-row>
                </el-row>
                <el-row ref="step-3" v-else-if="activeStep===2">
                    <el-row style="margin: 1vh 0;" class="school-college-label">
                        Do you give permission for your Guidance Counsellor to access your results and skillsvista account
                        information for guidance purposes? (this does not include email address or account password)
                    </el-row>
                    <div>
                        <el-row style="margin: 1.5vh 0;">
                            <div class="school-college-label">School Counsellor</div>
                        </el-row>
                        <div style="margin: 1.5vh 0;">
                            <el-radio-group v-model="guidanceCounsellor" class="school-college-label"
                                            style="display: block;">
                                <el-radio :label='true'>Yes</el-radio>
                                <el-radio :label="false">No</el-radio>
                            </el-radio-group>
                        </div>
                    </div>
                    <div>
                        <el-row style="margin: 1.5vh 0;">
                            <div class="school-college-label">Private Counsellor</div>
                        </el-row>
                        <div style="margin: 1.5vh 0 0;">
                            <el-radio-group v-model="privateCounsellor" class="school-college-label"
                                            style="display: block;">
                                <el-radio :label='true'>Yes</el-radio>
                                <el-radio :label="false">No</el-radio>
                            </el-radio-group>
                        </div>
                    </div>

                </el-row>
                <el-row style="margin: 1vh 0;" ref="step-4" v-else-if="activeStep===3">
                    <el-row>
                        <table style="text-align: left;" id="finalInfoTab">
                            <tr>
                                <td>School Name:</td>
                                <td><strong>{{schoolName}}</strong></td>
                            </tr>
                            <tr>
                                <td>School Year:</td>
                                <td><strong>{{currentYear}}th Year</strong></td>
                            </tr>
                            <tr>
                                <td>Favourite College:</td>
                                <td><strong>{{collegeName}}</strong></td>
                            </tr>
                            <tr>
                                <td>Permissions:</td>
                                <td>
                                    Private Counsellor (<strong>{{mapTrueFalse[privateCounsellor]}}</strong>)<br>
                                    School Counsellor(<strong>{{mapTrueFalse[guidanceCounsellor]}}</strong>)
                                </td>
                            </tr>
                        </table>
                    </el-row>
                </el-row>
            </el-row>
            <el-row style="padding: 0.5vh 1vw 0;">
                <div style="float: left;">
                    <el-button
                            v-if="activeStep!==0"
                            class="el-icon-right" @click="goPrev" type="text"
                            style="font-size: 50px; transform: rotate(180deg); padding: 0"></el-button>
                    <i v-else style="color: transparent">.</i>
                </div>
                <div v-if="(activeStep === 0 && schoolName !== '' && currentYear !== '') || (activeStep === 1 && collegeName !== '') || (activeStep === 2 && guidanceCounsellor !== '')"
                     style="float: right;">
                    <el-button class="el-icon-right" @click="goNext" type="text"
                               style="font-size: 50px; padding: 0"></el-button>
                </div>
                <div v-else-if="activeStep===3" style="float: right;">
                    <el-row>
                        <el-button @click="setUserInfo" type="primary">Submit</el-button>
                    </el-row>
                </div>
            </el-row>

        </el-dialog>
    </el-row>
</template>

<script>
  import {mapActions} from 'vuex';
  import LoadingScreen from './LoadingScreen';

  export default {
    name: 'PersonalInformation',
    components: {LoadingScreen},
    props: ['status', 'schools', 'colleges'],
    mounted() {
      this.dialogVisible = this.status;
      // this.loadAll();
    },
    data: function() {
      return {
        mapTrueFalse: {true: 'Yes', false: 'No'},
        activeStep: 0,
        dialogVisible: false,
        schoolName: '',
        collegeName: '',
        loadingScreen: false,
        error: '',
        guidanceCounsellor: true,
        privateCounsellor: true,
        currentYear: '',
        schoolYears: [{value: 4, label: '4th Year'}, {value: 5, label: '5th Year'}, {value: 6, label: '6th Year'}],
      };
    },
    methods: {
      ...mapActions(['setSchoolName', 'setFavouriteCollege']),
      goNext: function() {
        this.activeStep = this.activeStep + 1;
      },
      goPrev: function() {
        this.activeStep = this.activeStep - 1;
      },
      setUserInfo: function() {
        let self = this;
        this.error = '';
        if (this.schoolName === '') {
          this.error = 'Please select your school';
        }
        if (this.currentYear === '') {
          this.error = 'Please select current year in which you are studying';
        }
        if (this.collegeName === '') {
          this.error = 'Please select your preferred college';
        }
        if (this.error !== '') {
          return false;
        }
        self.$store.state.loadingScreen = true;
        let url = self.getBaseUrl() + '/keywords/user_info/set/';
        let data = {
          'college': self.collegeName,
          'school': self.schoolName,
          'school_year': self.currentYear,
          'private_guidance_counsellor': self.privateCounsellor,
          'school_counsellor_access': self.guidanceCounsellor,
        };
        $.ajax({
          beforeSend: function(request) {
            request.setRequestHeader('Authorization',
                'Token ' + self.getAuthToken());
          },
          url: url,
          type: 'POST',
          contentType: 'application/json',
          data: JSON.stringify(data),
          success: function(response) {
            response;
            self.setSchoolName(self.schoolName);
            self.setFavouriteCollege(self.collegeName);
            self.$emit('close');
            self.$store.state.loadingScreen = false;
            self.$message({
              title: 'Successful',
              message: 'You have successfully saved your school and favourite college.',
              type: 'success',
              duration: 3000,
              showClose: true,
            });
            self.error = '';
          },
          error: function(error) {
            self.error = '';
            self.$store.state.loadingScreen = false;
          },
        });
      },

    },
    computed: {},
  };
</script>
<style scoped>
    #finalInfoTab > tr, #finalInfoTab > tr > td {
        text-align: left !important;
        padding: 1vh 1vw;
        word-break: break-word;
    }
</style>
<style>
    .el-popup-parent--hidden {
        overflow: auto;
        -webkit-overflow-scrolling: touch
    }
</style>