<template>
    <div>
        <el-row :style="!isMobile()?{backgroundColor: '#17484C'}:''" style="display: flex; align-items: center; ">
            <el-col :span="22" class="component-title">
                CAO Points Calculator
            </el-col>
            <!--            <el-col :span="21" class="component-title" v-else>-->
            <!--                Your Predicted CAO Points:-->
            <!--                <span style="font-weight: 600; font-family: Niramit, serif !important;">-->
            <!--                    {{$store.state.predictedCAOPoints}}-->
            <!--                </span>-->
            <!--            </el-col>-->
            <el-col :span="2" v-if="!isMobile()">
                <profile-avatar></profile-avatar>
            </el-col>
        </el-row>
        <el-row :style="isMobile()?{height: '75vh'}:{height: 'calc(100vh - (3vh + 1vw) - 32px - 20px)'}"
                style="overflow-y: auto; overflow-x: hidden;">
            <el-row>
                <el-col :span="24" style="height: auto; overflow-y: auto; overflow-x: hidden">
                    <select-subjects :routedFrom="routedFrom" :source="'points_predict'"></select-subjects>
                    <!--                    <select-subjects-new></select-subjects-new>-->
                </el-col>
            </el-row>
            <!--            <unlock-banner v-if="!getIsPremiumUser || !getUserLoggedIn"></unlock-banner>-->
        </el-row>

    </div>
</template>

<script>
  import SelectSubjects from '../../main_components/SelectSubjects';
  import ProfileAvatar from '../../main_components/ProfileAvatar';
  import UnlockBanner from '../../main_components/UnlockBanner';
  import SelectSubjectsNew from '../../main_components/SelectSubjectsNew';

  export default {
    name: 'PointsPredict',
    props: ['routedFrom'],
    data: function() {
      return {
        loadingScreen: false,
      };
    },
    components: {SelectSubjectsNew, UnlockBanner, ProfileAvatar, SelectSubjects},
    beforeMount() {
      let self = this;
      self.$store.state.loadingScreen = true;
      self.checkUserLoggedIn().then(response => {
        if (response.user_type === 2) {
          self.$router.push('/counsellor/home/');
        }
        if (self.$store.state.isAdmin) {
          self.$router.push('/admin/');
        }
        self.$store.state.loadingScreen = false;
      }).catch(error => {
        error;
        self.$store.state.loadingScreen = false;
      });
    },
    mounted() {
      this.sendAnalytics();
      this.getPredictedCAOPoints();
    },
  };
</script>

<style>
    .el-checkbox__inner {
        border: 1px solid #6b6b6b !important;
    }
</style>

<style scoped>
    .points-predict-head {
        font-family: Niramit, serif !important;
        font-style: normal !important;
        font-weight: 300;
        text-align: justify;
        color: #000000;
    }
</style>
