<template>
    <svg :style="!isMobile() ? {'width': '2.5vw'}: {'width': 'calc(3.5vw + 3.5vh)'}" fill="none" viewBox="0 0 52 52"
         xmlns="http://www.w3.org/2000/svg">
        <path d="M23.9804 1.98048C24.2062 -0.4762 27.7938 -0.4762 28.0196 1.98048C28.2104 4.05704 31.0343 4.52827 31.889 2.62617C32.9002 0.375895 36.2935 1.54081 35.7093 3.93767C35.2155 5.96367 37.7334 7.3263 39.1594 5.80478C40.8464 4.00476 43.6776 6.20836 42.3468 8.28566C41.2219 10.0415 43.161 12.1479 45.0037 11.1719C47.1839 10.0172 49.1461 13.0206 47.2129 14.5533C45.5789 15.8488 46.7289 18.4706 48.7888 18.1458C51.2257 17.7615 52.1064 21.2394 49.7803 22.0613C47.8141 22.756 48.0505 25.6092 50.1043 25.9709C52.5339 26.3987 52.2376 29.9741 49.7707 29.9962C47.6855 30.0148 46.9827 32.7902 48.8077 33.7991C50.9668 34.9926 49.5256 38.2781 47.1852 37.498C45.2069 36.8386 43.641 39.2354 45.0396 40.7821C46.6941 42.6121 44.2642 45.2516 42.3039 43.7538C40.6469 42.4878 38.3876 44.2462 39.2082 46.1633C40.1789 48.4314 37.0236 50.1389 35.6559 48.0857C34.4997 46.3503 31.7919 47.2799 31.9455 49.3595C32.1272 51.8198 28.5885 52.4104 27.9615 50.0243C27.4315 48.0075 24.5685 48.0075 24.0385 50.0243C23.4115 52.4104 19.8728 51.8198 20.0545 49.3595C20.2081 47.2799 17.5003 46.3503 16.3441 48.0857C14.9764 50.1389 11.8211 48.4314 12.7918 46.1633C13.6124 44.2462 11.3531 42.4878 9.69607 43.7538C7.73576 45.2516 5.30589 42.6121 6.96045 40.7821C8.35901 39.2354 6.79311 36.8386 4.81481 37.498C2.47438 38.2781 1.03323 34.9926 3.19232 33.7991C5.01734 32.7902 4.31452 30.0148 2.22929 29.9962C-0.237637 29.9741 -0.533905 26.3987 1.89574 25.9709C3.94946 25.6092 4.18588 22.756 2.21969 22.0613C-0.106398 21.2394 0.774324 17.7615 3.21124 18.1458C5.2711 18.4706 6.42114 15.8488 4.78707 14.5533C2.85387 13.0206 4.81615 10.0172 6.99626 11.1719C8.83904 12.1479 10.7781 10.0415 9.65319 8.28566C8.32239 6.20836 11.1536 4.00476 12.8406 5.80478C14.2666 7.3263 16.7845 5.96367 16.2907 3.93767C15.7065 1.54081 19.0998 0.375895 20.111 2.62617C20.9657 4.52827 23.7896 4.05704 23.9804 1.98048Z"
              fill="#377F7E"/>
        <circle cx="26.0001" cy="26.0001" fill="white" r="17.8065"/>
        <text dominant-baseline="middle" fill="black" lengthAdjust="spacingAndGlyphs" text-anchor="middle"
              textLength="40" x="50%" y="50%">
            {{percentage}}
        </text>

    </svg>
</template>

<script>

  export default {
    props: ['percentage'],
    name: 'OverallStarBadge',
  };
</script>

<style scoped>

</style>