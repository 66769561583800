<template>
    <el-dialog :append-to-body="true" 
            :before-close="emitParentEvent" 
            :visible.sync="dialogVisible"
            class="el-dialog-copy-1" width="100%">
        <career-info-page :data="data"/>
    </el-dialog>
</template>

<script>

  import CareerInfoPage from '../../main_components/CareerInfoPage';

  export default {
    components: {CareerInfoPage},
    props: ['dialogVisible', 'data'],
    name: 'PopoverBlock',
    mounted() {
      this.$nextTick(function() {
        let echarts = require('echarts');
        let dom = dom = document.getElementById('doughnutChart');

        this.chartData.legend.data = this.data.cd[1];
        this.chartData.series[0].data = this.data.cd[0];
        this.chartData.series[0].color = this.pieChartColorPalette;
        let instance = echarts.init(dom, null, {renderer: 'svg'});
        instance.setOption(this.chartData);
      });

    },
    data: function() {
      return {
        pieChartColorPalette: [
          '#788DD8',
          '#8F91D2',
          '#BABCE4',
          '#A8B5E5',
          '#768ad6',
          '#788DD8',
          '#4e69ca',
          '#BABCE4',
          '#6179d1',
          '#3958c6'],
        chartData: {
          tooltip: {
            // trigger: 'item',
            fontSize: 10,
            formatter: '{a} <br/>{b}: {c} ({d}%)',
          },
          legend: {
            show: false,
            data: [],
          },
          series: [
            {
              name: '',
              color: [],
              type: 'pie',
              radius: ['30%', '50%'],
              center: ['50%', '50%'],
              avoidLabelOverlap: false,
              label: {
                show: false,
                position: 'center',
              },
              emphasis: {},
              labelLine: {
                show: false,
              },
              data: [],
            },
          ],
        },
      };
    },
    methods: {
      emitParentEvent: function() {
        this.$emit('closeDialog', false);
      },
    },
  };
</script>
<style>
  .el-dialog-copy-1 .el-dialog__body {
      padding: 0 16px !important;
  }

  .el-dialog-copy-1 > .el-dialog__header {
      padding: 1vh 3vw !important;
  }
</style>
