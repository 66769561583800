<template>
    <div id="app">
        <loading-screen v-if="loadingScreen"></loading-screen>
        <el-container>
            <loading-screen :message="$store.state.loadingMessage" v-if="getLoadingScreenStatus"></loading-screen>
            <!-- <navbar :sidebar_collapse="collapse" :style="getNavStyle"></navbar>  -->
            <!-- <navbar :style="getNavStyle"></navbar> -->
            <mobile-navbar :collapse.sync="getCollapse"
                           :style="getNavStyle"
                           @changeCollapse="toggleCollapse()"
                           @click.native="isMobile()?collapse=true:false"
                           v-if="isMobile() && getRoute !== 'Login' && getRoute !== 'SignUp'"></mobile-navbar>
            <el-aside :style="getWidth" v-if="getRoute !== 'Login' && getRoute !== 'SignUp' && showSidebarStore">
                <sidebar :collapse.sync="collapse"></sidebar>
            </el-aside>
            <el-main :style="returnStyle" @click.native="isMobile()?collapse=true:false" class="main-basic secondary-bg-color">
                <el-row>
                    <el-col>
                        <router-view :key="$route.fullPath"></router-view>
                    </el-col>
                </el-row>
            </el-main>
        </el-container>
        <personal-information
                :colleges="$store.state.colleges"
                :schools="$store.state.schools"
                :status="noSchoolCollegeInfo" @close="closeDialogue"
                v-if="noSchoolCollegeInfo && !getIsAdmin && !getIsCounsellor && !getLoadingScreenStatus && !loadingScreen">
        </personal-information>
        <feedback-form :status="getShowFeedbackDialogue" @closeFeedback="closeFeedback"
                       v-if="getShowFeedbackDialogue"></feedback-form>
        <div style="
                position: absolute;
                bottom: 20px;
                right: 100px;
                background-color: transparent;
                border-radius: 30px;
                width: 60px;
                background-size: cover;
                height: 60px;
                border: 1px solid lightblue;
                cursor: pointer;"
             v-if="getUserLoggedIn && !$store.state.feedbackGiven && $store.state.recommendationsGenerated"
             v-on:click="$store.state.showFeedbackDialogue=true"
             :style="{'background-image': 'url(' + publicPath + 'img/feedback.png)'}">
        </div>
        <CookieDialog @closeCookie="refreshPage" v-if="getCookieConsent" :dialogVisible="getCookieConsent"/>
    </div>
</template>

<script>
import Sidebar from './main_components/sidebar';
import MobileNavbar from './main_components/MobileNavbar';
import PersonalInformation from './main_components/PersonalInformation';
import FeedbackForm from './main_components/FeedbackForm';
import LoadingScreen from './main_components/LoadingScreen';
import CookieDialog from "./Pages/BasicPages/CookieDialog";
import * as Cookies from "js-cookie";

export default {
    data: function () {
        return {
            loadingScreen: false,
            collapse: false,
            showDialogue: true,
            schools: [],
            colleges: [],
            // showFeedbackDialogue: false,
        };
    },
    mounted() {
        let self = this;
        var element = document.createElement('link');
        var element1 = document.createElement('link');

        element.setAttribute('rel', 'stylesheet');
        element.setAttribute('type', 'text/css');

        element1.setAttribute('rel', 'stylesheet');
        element1.setAttribute('type', 'text/css');

        if (self.isMobile()) {
            element.setAttribute('href', `${self.publicPath}css/mobile-style.css`);
            element1.setAttribute('href', `${self.publicPath}css/course-page-mobile.css`);
        } else {
            element.setAttribute('href', `${self.publicPath}css/web-style.css`);
            element1.setAttribute('href', `${self.publicPath}css/course-page-web.css`);
        }
        document.getElementsByTagName('head')[0].appendChild(element);
        document.getElementsByTagName('head')[0].appendChild(element1);
        // self.showFeedbackDialogue = !self.$store.state.feedbackGiven;
        // },
        // beforeMount() {
        //   let self = this;
        // self.loadingScreen = true;
        self.collapse = self.isMobile();
        self.checkUserLoggedIn().then(response => {
            if (response.status !== 0) {
                if (!response.is_admin) {
                    self.getSchoolCollege().then(response0 => {
                        self.loadingScreen = false;
                    });
                }
                self.getUserInfo().then(response1 => {
                    self.getColleges().then(response2 => {
                        self.getSchools().then(response3 => {
                            self.loadingScreen = false;
                        });
                    });
                });
            } else {
                self.loadingScreen = false;
            }
        });
    },
    computed: {
        getCookieConsent() {
            return Cookies.get("e_c_g") === undefined;
        },
        showSidebarStore: function () {
            return this.$store.state.showSidebar;
        },
        getShowFeedbackDialogue: function () {
            return this.$store.state.showFeedbackDialogue;
        },
        getRoute: function () {
            if (this.$route.name === undefined || this.$route.name === null) {
                return '';
            }
            return this.$route.name.replace(/\b\w/g, l => l.toUpperCase());
        },
        getCollapse: function () {
            return this.collapse;
        },
        returnStyle: function () {
            let styleObj = {};
            // styleObj['transition'] = '0.30s all ease';
            styleObj['background-image'] = 'url(\'' + this.publicPath + 'img/organic shape.svg\')';
            let routeName = '';
            if (this.$route.name !== null) {
                routeName = this.$route.name.replace(/\b\w/g, l => l.toUpperCase());
            }
            if (this.isMobile()) {
                styleObj['position'] = 'fixed';
                if (this.$route.name !== 'Login' && this.$route.name !== 'SignUp')
                    styleObj['margin-top'] = '60px !important';
                styleObj['padding'] = '0';
            } else {
                styleObj['margin-top'] = '0vh'; //changes for @high-fidelity
                if (routeName !== 'Login' && routeName !== 'SignUp')
                    styleObj['padding'] = '0';
                else
                    styleObj['padding'] = '0';
            }

            if (!this.isMobile()) {
                if (this.collapse) {
                    if (routeName !== 'Login' && routeName !== 'SignUp')
                        styleObj['margin-left'] = '4vw';
                } else {
                    if (routeName !== 'Login' && routeName !== 'SignUp') {
                        if (this.$store.state.showSidebar)
                            styleObj['margin-left'] = '14%';
                    }
                }

            }
            return styleObj;
        },

        getNavStyle: function () {
            if (!this.isMobile()) {
                return {
                    transition: 'all 0.5s',
                    width: 'calc(100% - 14%)',
                    left: '14%',
                    height: '6vh',
                    top: 0,
                    position: 'fixed',
                    'z-index': '999',
                };
            } else {
                return {
                    transition: 'all 0.5s',
                    width: '100%',
                    height: '60px !important',
                    position: 'fixed',
                    'z-index': '999',
                };
            }

        },
        getWidth: function () {
            let styleObject = {'z-index': '2000', 'position': 'fixed', 'height': '100vh'};
            if (!this.isMobile()) {
                if (this.collapse) {
                    styleObject['width'] = '4vw';
                } else {
                    styleObject['width'] = '14%';
                }
            } else {
                if (this.collapse) {
                    styleObject['width'] = '0';
                } else {
                    styleObject['width'] = '60vw';
                }
            }
            styleObject['transition'] = 'all 0.5s';
            styleObject['overflow'] = 'hidden';
            // styleObject['-webkit-box-shadow'] = '0 8px 6px -1px black';
            // styleObject['-moz-box-shadow'] = '0 8px 6px -1px black';
            // styleObject['box-shadow'] = '0 8px 6px -1px black';
            return styleObject;
        },
    },
    methods: {
        refreshPage() {
            location.reload();
        },
        closeFeedback: function () {
            this.$store.state.showFeedbackDialogue = false;
        },
        closeDialogue: function () {
            this.showDialogue = false;
        },
        toggleCollapse: function () {
            this.collapse = !this.collapse;
        },
    },
    name: 'App',
    components: {
        CookieDialog,
        LoadingScreen,
        FeedbackForm,
        PersonalInformation,
        // Navbar,
        MobileNavbar,
        Sidebar,
    },
};
</script>

<style>
/*Fixes double tap on iOS*/
.el-scrollbar__bar {
    opacity: 1 !important;
}

.main-basic {
    overflow: hidden !important;
    padding: 0;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh !important;
    width: 100vw !important;
}

.non_full_width {
    width: calc(100vw - 17%);
    left: 17%;
}

.full_width {
    width: calc(100vw - 5%);
    left: 5%;
}

#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    z-index: 9;
    background-color: #377F7E;
}
</style>
