<template>
    <div>
        <div :style="source==='home' ? {'background': '#3A3F5C'}:{}" class="main-subject-div">
            <el-row>
                <el-col>
                    <div
                            class="caoPrediction caoPrediction-points"
                            v-if="!getUserLoggedIn || !$store.state.predictedCAOPoints">
                        Predict your CAO Points
                    </div>
                    <div
                            class="caoPrediction caoPrediction-points"
                            v-else>
                        Your Predicted CAO Points:
                        <span style="font-weight: 600;">
                            {{$store.state.predictedCAOPoints}}
                        </span>
                    </div>
                </el-col>
            </el-row>

            <el-row :gutter="40">
                <el-col :lg="11" :md="11" :sm="24" :xl="11" :xs="24">
                    <div style="text-align: justify; margin: 2vh 0; color: #000;">
                        <h4>Subject</h4>
                    </div>
                </el-col>
                <el-col :lg="5" :md="5" :xl="5" :sm="12" :xs="12" v-if="!isMobile()">
                    <div style="text-align: justify; margin: 2vh 0;">
                        <h4>Level</h4>
                    </div>
                </el-col>
                <el-col :lg="5" :md="5" :xl="5" :sm="12" :xs="12" v-if="!isMobile()">
                    <div style="text-align: justify; margin: 2vh 0;">
                        <h4>Grade</h4>
                    </div>
                </el-col>
            </el-row>
            <el-row :gutter="isMobile() ? 20:40" style="margin-bottom: 1vh;">
                <el-col :xl="11" :lg="11" :md="11" :sm="24" :xs="24" :style="isMobile() ? {'margin-bottom': '1vh'}: {}">
                    <el-autocomplete
                            @input="currentLevel='', currentGrade=''"
                            @change="checkIfCorrect"
                            v-if="source!=='home' && getCompulsorySubjects.length === 0"
                            :disabled="source !== 'points_predict'"
                            class="el-input search-input1"
                            v-model="currentSubject"
                            :title="selectedSubjectsList.length === 10 ? 'You can select only 10 subjects':currentSubject"
                            placeholder="--Please select a subject--"
                            :fetch-suggestions="querySubjectsSearchAsync"
                    ></el-autocomplete>
                    <el-select
                            @change="checkIfCorrect"
                            v-else-if="source!=='home' && getCompulsorySubjects.length !== 0"
                            class="el-input search-input1"
                            v-model="currentSubject"
                            :title="currentSubject"
                            :placeholder="currentSubject">
                        <el-option :label="getCompulsorySubjects[0].subject_name"
                                   :value="getCompulsorySubjects[0].subject_name"></el-option>
                        <el-option v-if="getCompulsorySubjects[0].subject_name_other !== ''"
                                   :label="getCompulsorySubjects[0].subject_name_other"
                                   :value="getCompulsorySubjects[0].subject_name_other"></el-option>
                    </el-select>
                    <!--                    <el-select :disabled="selectedSubjectsList.length === 10 || source !== 'points_predict'"-->
                    <!--                               @change="updateSubjectList"-->
                    <!--                               @focus="updateSubjectList"-->
                    <!--                               filterable-->
                    <!--                               placeholder="&#45;&#45;Please select a subject&#45;&#45;"-->
                    <!--                               :title="selectedSubjectsList.length === 10 ? 'You can select only 10 subjects':'&#45;&#45;Please select a subject&#45;&#45;'"-->
                    <!--                               style="width: 100%;"-->
                    <!--                               v-if="source!=='home' && !isMobile()"-->
                    <!--                               v-model="currentSubject">-->
                    <!--                        <el-option-->
                    <!--                                :key="item.value"-->
                    <!--                                :label="item.value"-->
                    <!--                                :value="item.value"-->
                    <!--                                v-for="item in getSubjectsList">-->
                    <!--                        </el-option>-->
                    <!--                    </el-select>-->
                    <div :class="{'blur-subject': !getUserLoggedIn}" class="home-subject-display" v-else>
                        Subject Title
                    </div>
                </el-col>

                <el-col :xl="5" :lg="5" :md="5" :sm="12" :xs="12">
                    <el-select @change="currentGrade=''" style="width: 100%; "
                               :disabled="getStatus1() || source !== 'points_predict'"
                               :title="currentLevel"
                               placeholder="--Please select a grade--" v-model="currentLevel">
                        <el-option
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                                v-for="item in getLevelOptions">
                        </el-option>
                    </el-select>

                </el-col>
                <el-col :lg="5" :md="5" :sm="12" :xl="5" :xs="12">
                    <el-select style="width: 100%; " :disabled="getStatus2() || source !== 'points_predict'"
                               :title="currentGrade"
                               @input="setFocusToButton"
                               placeholder="--Please select a level--" v-model="currentGrade">
                        <el-option
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                                v-for="item in getGrades">
                        </el-option>
                    </el-select>
                </el-col>
                <el-col :lg="3" :md="3" :sm="24" :style="isMobile() ? {'margin-top': '1vh'}: {}" :xl="3"
                        :xs="24"
                        v-if="!getStatus4() && selectedSubjectsList.length < 10">
                    <button @click="addEmptySubject"
                            class="edit-subject-button"
                            ref="addButton">
                        Add
                    </button>
                </el-col>
            </el-row>
            <div style="text-align: justify; margin: 4vh 0 2vh;" v-if="selectedSubjectsList.length !== 0">
                <h4>Selected Subjects ({{getSubjectListLength}})</h4>
            </div>

            <el-row :gutter="5" :key="key" class="subjectRow"
                    v-for="(subjectItem, key) in getSelectedSubjectsList">
                <el-col v-if="subjectItem.subject_name !== ''" :xl="8" :lg="8" :md="8" :sm="8" :xs="8">
                    <div class="select-subject-display">
                        {{getSubjectName(subjectItem.subject_name)}}
                    </div>
                </el-col>
                <el-col v-if="subjectItem.subject_name !== ''" :xl="4" :lg="4" :md="4" :sm="5" :xs="5">
                    <div class="select-subject-display" v-if="subjectItem.level !== ''">
                        {{getLevel(subjectItem.level)}}
                    </div>
                    <div class="select-subject-display" v-else>
                        -
                    </div>

                </el-col>
                <el-col v-if="subjectItem.subject_name !== ''" :xl="4" :lg="4" :md="4" :sm="6" :xs="6">
                    <div class="select-subject-display">
                        {{getGradeLabel(subjectItem.grade)}}
                    </div>
                </el-col>
                <el-col v-if="subjectItem.subject_name !== ''" :xl="1" :lg="1" :md="1" :sm="2" :xs="2">
                    <el-button @click="editEntry(subjectItem.id)"
                               circle class="subjectRowDelete"
                               icon="el-icon-edit"
                               :disabled="subjectEditID"
                               type="success" v-if="!getStatus3(subjectItem)"></el-button>
                </el-col>
                <el-col v-if="subjectItem.subject_name !== ''" l :xl="{span: 1, offset: 0}" :lg="{span: 1, offset: 0}"
                        :md="{span: 1, offset: 0}"
                        :sm="{span: 2, offset: 1}" :xs="{span: 2, offset: 1}">
                    <el-button @click="deleteEntry(subjectItem.id)"
                               circle class="subjectRowDelete"
                               icon="el-icon-delete"
                               type="danger" v-if="!getStatus3(subjectItem)"></el-button>
                </el-col>
            </el-row>

            <el-row style="margin-top: 1%; width: 100%; text-align: left; color: rgb(224, 126, 37);"
                    v-if="!isMobile()">
                <el-col :xl="3" :lg="4" :md="12" :sm="12" :xs="12">
                    <el-checkbox :checked="getIrishExempted" @change="toggleExemptIrishChecked"
                                 style="color: rgb(0, 0, 0, 0.7);font-weight: bold;"
                                 v-model="exemptIrishChecked">
                        Exempt from Irish
                    </el-checkbox>
                </el-col>
                <el-col :offset="1" :xl="3" :lg="4" :md="12" :sm="12" :xs="12">
                    <el-checkbox :checked="getLanguagesExempted" @change="toggleExemptLanguagesChecked"
                                 style="color: rgb(0, 0, 0, 0.7);font-weight: bold;"
                                 v-model="exemptLanguagesChecked">
                        Exempt from Languages
                    </el-checkbox>
                </el-col>
            </el-row>
        </div>
        <el-row style="width: 95%; margin: 0 auto; text-align: left; color: rgb(224, 126, 37);"
                v-if="isMobile()">
            <el-col>
                <el-checkbox style="color: rgb(224, 126, 37);" v-model="exemptIrishChecked">Exempt from Irish
                </el-checkbox>
            </el-col>
            <el-col>
                <el-checkbox style="color: rgb(224, 126, 37);" v-model="exemptLanguagesChecked">Exempt from
                    Languages
                </el-checkbox>
            </el-col>
            <el-col
                    style="text-align: left;">
                <el-checkbox v-model="doubleChecked">
                    <div class='termsCondition' style=" color: white; word-break: break-word; white-space: break-spaces;">
                        Please double check that ALL subjects are filled in correctly before final submission
                    </div>
                </el-checkbox>
            </el-col>
        </el-row>
        <el-row style="width: 90%; margin: 1vh auto; position: relative;">
            <el-col :lg="19" :md="19" :sm="24" :xl="19" :xs="24" style="text-align: left;"
                    v-if="!isMobile()">
                <el-checkbox v-model="doubleChecked">
                    <span class='termsCondition' style="color: white;">
                        Please double check that ALL subjects are filled in correctly before final submission
                    </span>
                </el-checkbox>
            </el-col>

            <el-col :xl="{span: 3, offset: 1}" :lg="{span: 3, offset: 1}" :md="{span: 3, offset: 1}" :sm="{span:24}"
                    :xs="{span:24}">
              <div class="tooltip-container">
                <el-tooltip placement="top" class="toottip">
                  <template #content>1. Please accept the checkbox<br />2. Please select 6-10 subjects </template>
                  <i class="el-icon-info"></i>
                </el-tooltip>
                <el-button :disabled="!showPredictButton"
                           @click="predictCAOPoints"
                           style="margin-top: 0 !important;"
                           class="black-primary-button takeQuizButton">
                    <span v-if="routedFrom === 'skillsvista_fit'">Submit Predictions</span>
                    <span v-else>Predict CAO Points</span>
                </el-button>
              </div>
            </el-col>
        </el-row>
    </div>

</template>

<script>
  import $ from 'jquery';
  import {mapActions} from 'vuex';
  import LoadingScreen from './LoadingScreen';

  export default {
    components: {LoadingScreen},
    props: ['source', 'routedFrom'],
    name: 'SelectSubjects',
    computed: {
      getCompulsorySubjects: function() {
        let self = this;
        return this.compulsorySubjects.filter(function(element) {
          let subject = element.subject_name.toUpperCase();
          let filteredIndex = self.selectedSubjectsList.findIndex(function(data) {
            if (subject === 'LANGUAGES') {
              return self.languages.includes(data.subject_name.toUpperCase());
            } else {
              return data.subject_name.toUpperCase().includes(subject) &&
                  !data.subject_name.toUpperCase().includes('APPLIED');
            }
          });
          if (filteredIndex === -1) {
            if (subject.includes('IRISH') && self.exemptIrishChecked) {
              return false;
            } else if (subject.includes('LANGUAGES') && self.exemptLanguages) {
              return false;
            }
            return true;
          }
        });
      },
      getSelectedSubjectsList: function() {
        return this.selectedSubjectsList;
      },
      getLevelOptions: function() {
        if (this.currentSubject.toLowerCase().includes('foundation')) {
          return this.levelOptionsFoundation;
        }
        return this.levelOptions;
      },
      getSubjectListLength: function() {
        return this.selectedSubjectsList.filter(function(element) {
          return element.subject_name !== '';
        }).length;
      },
      getIrishExempted: function() {
        return this.exemptIrishChecked;
      },
      getLanguagesExempted: function() {
        return this.exemptLanguagesChecked;
      },
      showPredictButton: function() {
        return this.selectedSubjectsList.length > 5 && this.doubleChecked;
      },
      getGrades: function() {
        if (this.currentSubject === 'LCVP LINK MODULES') {
          return this.lcvpGrades;
        } else {
          if (this.currentLevel === 'HIGHER') {
            return this.higherGradeOptions;
          } else if (this.currentLevel === 'FOUNDATION') {
            return this.foundationGrades;
          } else {
            return this.ordGradeOptions;
          }
        }
      },
    },
    mounted() {
      let self = this;
      self.fetchSubjects('points');
      if (!self.$store.getters.getUserLoggedIn) {
        self.$router.push('/login/');
      }
      self.checkUserLoggedIn().then(response => {
        self.exemptLanguagesChecked = response.exempt_languages;
        self.exemptIrishChecked = response.exempt_irish;
        self.$store.state.loadingScreen = false;
      }).catch(error => {
        error;
        self.$store.state.loadingScreen = false;
      });
    },
    data: () => ({
      loadingScreen: false,
      mathsSelected: false,
      englishSelected: false,
      irishSelected: false,
      doubleChecked: false,
      languageSelected: false,
      exemptLanguagesChecked: false,
      exemptIrishChecked: false,
      currentSubject: '',
      currentLevel: '',
      currentGrade: '',
      userLoggedIn: false,
      selectedLevel: null,
      compulsorySubjects: [
        {
          'text': 'Select your grades for ',
          'subject_name': 'ENGLISH',
          'subject_name_other': '',
          'level': '',
          'grade': '',
        },
        {
          'text': 'Select either of the options',
          'subject_name': 'MATHEMATICS',
          'subject_name_other': 'MATHEMATICS (FOUNDATION LEVEL)',
          'level': '',
          'grade': '',
        },
        {
          'text': 'Select either of the options',
          'subject_name': 'IRISH',
          'subject_name_other': 'IRISH (FOUNDATION LEVEL)',
          'level': '',
          'grade': '',
        },
        // {
        //   'text': 'Select another language',
        //   'subject_name': 'Languages',
        //   'subject_name_other': '',
        //   'level': '',
        //   'grade': '',
        // },
      ],
      languages: [
        'FRENCH',
        'GERMAN',
        'ARABIC',
        'DUTCH',
        'HEBREW STUDIES',
        'ITALIAN',
        'JAPANESE',
        'LATIN',
        'OTHER EU LANGUAGE',
        'OTHER FOREIGN LANGUAGE',
        'POLISH',
        'PORTUGUESE',
        'RUSSIAN',
        'SPANISH',
      ],
      selectedGrade: null,
      selectedSubject: null,
      selectedSubjectsList: [
        {
          'subject_name': '',
          'level': '',
          'grade': '',
        }],
      subjectOptions: [
        {value: 'ACCOUNTING', text: 'ACCOUNTING'},
        {value: 'AGRICULTURAL ECONOMICS', text: 'AGRICULTURAL ECONOMICS'},
        {value: 'AGRICULTURAL SCIENCE', text: 'AGRICULTURAL SCIENCE'},
        {value: 'ANCIENT GREEK', text: 'ANCIENT GREEK'},
        {value: 'APPLIED MATHEMATICS', text: 'APPLIED MATHEMATICS'},
        {value: 'ARABIC', text: 'ARABIC'},
        {value: 'ART', text: 'ART'},
        {value: 'BIOLOGY', text: 'BIOLOGY'},
        {value: 'BUSINESS', text: 'BUSINESS'},
        {value: 'CHEMISTRY', text: 'CHEMISTRY'},
        {value: 'CLASSICAL STUDIES', text: 'CLASSICAL STUDIES'},
        {value: 'COMPUTER SCIENCE', text: 'COMPUTER SCIENCE'},
        {value: 'CONSTRUCTION STUDIES', text: 'CONSTRUCTION STUDIES'},
        {value: 'DESIGN & COMMUNICATION GRAPHIC', text: 'DESIGN & COMMUNICATION GRAPHIC'},
        {value: 'DUTCH', text: 'DUTCH'},
        {value: 'ECONOMICS', text: 'ECONOMICS'},
        {value: 'ENGLISH', text: 'ENGLISH'},
        {value: 'ENGINEERING', text: 'ENGINEERING'},
        {value: 'FRENCH', text: 'FRENCH'},
        {value: 'GERMAN', text: 'GERMAN'},
        {value: 'GEOGRAPHY', text: 'GEOGRAPHY'},
        {value: 'HEBREW STUDIES', text: 'HEBREW STUDIES'},
        {value: 'HISTORY', text: 'HISTORY'},
        {value: 'HOME ECONOMICS', text: 'HOME ECONOMICS'},
        {value: 'IRISH', text: 'IRISH'},
        {value: 'IRISH (FOUNDATION LEVEL)', text: 'IRISH (FOUNDATION LEVEL)'},
        {value: 'ITALIAN', text: 'ITALIAN'},
        {value: 'JAPANESE', text: 'JAPANESE'},
        {value: 'LATIN', text: 'LATIN'},
        {value: 'LCVP LINK MODULES', text: 'LCVP LINK MODULES'},
        {value: 'MATHEMATICS', text: 'MATHEMATICS'},
        {value: 'MATHEMATICS (FOUNDATION LEVEL)', text: 'MATHEMATICS (FOUNDATION LEVEL)'},
        {value: 'MUSIC', text: 'MUSIC'},
        {value: 'OTHER EU LANGUAGE', text: 'OTHER EU LANGUAGE'},
        {value: 'OTHER FOREIGN LANGUAGE', text: 'OTHER FOREIGN LANGUAGE'},
        {value: 'PHYSICAL EDUCATION', text: 'PHYSICAL EDUCATION'},
        {value: 'PHYSICS AND CHEMISTRY', text: 'PHYSICS AND CHEMISTRY'},
        {value: 'PHYSICS', text: 'PHYSICS'},
        {value: 'POLISH', text: 'POLISH'},
        {value: 'POLITICS AND SOCIETY', text: 'POLITICS AND SOCIETY'},
        {value: 'PORTUGUESE', text: 'PORTUGUESE'},
        {value: 'RELIGIOUS EDUCATION', text: 'RELIGIOUS EDUCATION'},
        {value: 'RUSSIAN', text: 'RUSSIAN'},
        {value: 'SPANISH', text: 'SPANISH'},
        {value: 'TECHNOLOGY', text: 'TECHNOLOGY'},
      ],
      updatedSubjectOptions: [],
      levelOptions: [
        {value: 'HIGHER', text: 'HIGHER'},
        {value: 'ORDINARY', text: 'ORDINARY'},
      ],
      levelOptionsFoundation: [
        {value: 'FOUNDATION', text: 'FOUNDATION'},
      ],
      higherGradeOptions: [
        {value: 'H1', label: 'H1 (90-100%)'},
        {value: 'H2', label: 'H2 (80-89%)'},
        {value: 'H3', label: 'H3 (70-79%)'},
        {value: 'H4', label: 'H4 (60-69%)'},
        {value: 'H5', label: 'H5 (50-59%)'},
        {value: 'H6', label: 'H6 (40-49%)'},
        {value: 'H7', label: 'H7 (30-39%)'},
        {value: 'H8', label: 'H8 (0-30%)'},
      ],
      foundationGradeOptions: [
        {value: 'F1', label: 'F1'},
        {value: 'F2', label: 'F2'},
      ],
      gradeMapping: {
        'O1': 'O1 (90-100%)',
        'O2': 'O2 (80-89%)',
        'O3': 'O3 (70-79%)',
        'O4': 'O4 (60-69%)',
        'O5': 'O5 (50-59%)',
        'O6': 'O6 (40-49%)',
        'O7': 'O7 (30-39%)',
        'O8': 'O8 (0-30%)',
        'H1': 'H1 (90-100%)',
        'H2': 'H2 (80-89%)',
        'H3': 'H3 (70-79%)',
        'H4': 'H4 (60-69%)',
        'H5': 'H5 (50-59%)',
        'H6': 'H6 (40-49%)',
        'H7': 'H7 (30-39%)',
        'H8': 'H8 (0-30%)',
        'F1': 'F1',
        'F2': 'F2',
        '1': 'DISTINCTION',
        '2': 'MERIT',
        '3': 'PASS',
      },
      ordGradeOptions: [
        {value: 'O1', label: 'O1 (90-100%)'},
        {value: 'O2', label: 'O2 (80-89%)'},
        {value: 'O3', label: 'O3 (70-79%)'},
        {value: 'O4', label: 'O4 (60-69%)'},
        {value: 'O5', label: 'O5 (50-59%)'},
        {value: 'O6', label: 'O6 (40-49%)'},
        {value: 'O7', label: 'O7 (30-39%)'},
        {value: 'O8', label: 'O8 (0-30%)'},
      ],
      foundationGrades: [
        {value: 'F1', label: 'F1'},
        {value: 'F2', label: 'F2'},
      ],
      lcvpGrades: [
        {value: '1', label: 'DISTINCTION'},
        {value: '2', label: 'MERIT'},
        {value: '3', label: 'PASS'},
      ],
      subjectEditID: false,
    }),
    methods: {
      ...mapActions([
        'setPredictedCAOPoints',
        'setExemptLanguages',
        'setExemptIrish',
        'setPredictionsSubmitted',
      ]),
      checkIfCorrect: function() {
        let self = this;
        let filteredData = self.subjectOptions.filter(function(element) {
          if (element.value === self.currentSubject)
            return element;
        });
        if (filteredData.length === 0)
          self.currentSubject = '';
      },
      getSelectedSubjectsForFilter: function(){
        // From the selected subjects filtering out the subjects which are alternatives or excempted 
        let subjectsNames = this.selectedSubjectsList.map(function(value) {
          return value.subject_name.toLowerCase();
        });
        if ((subjectsNames.includes('irish') || subjectsNames.includes('irish (foundation level)')) 
            || this.exemptIrishChecked){
            subjectsNames.push('irish');
            subjectsNames.push('irish (foundation level)');
          }
        if (subjectsNames.includes('mathematics (foundation level)') || subjectsNames.includes('mathematics')) {
            subjectsNames.push('mathematics (foundation level)');
            subjectsNames.push('mathematics');
        }
        if(this.exemptLanguagesChecked){
          let languageList = this.languages.map(function(value) {
            return value.toLowerCase();
          });
          subjectsNames.push(...languageList);
        }
        return subjectsNames;
      },
      updateSubjectList: function() {
        this.currentLevel = '';
        this.currentGrade = '';
        let self = this;
        let subjectsNames = self.getSelectedSubjectsForFilter();
        let filteredData = JSON.parse(JSON.stringify(self.subjectOptions));
        filteredData = filteredData.filter(function(element) {
          if (!subjectsNames.includes(element.text.toLowerCase()))
            return element;
        });
        this.updatedSubjectOptions = filteredData;
      },
      setFocusToButton: function() {
        let self = this;
        self.$nextTick(function() {
          self.$refs.addButton.focus();
        });
      },
      getSubjects: function() {
        let self = this;
        let subjectsNames = self.getSelectedSubjectsForFilter();
        return self.subjectOptions.filter(function(element) {
          if (!subjectsNames.includes(element.text.toLowerCase()))
            return element;
        });
      },
      querySubjectsSearchAsync: function(queryString, cb) {
        let programList = [];
        let subjectList = this.getSubjects();
        for (let i = 0; i < subjectList.length; i++) {
          programList.push({
            value: subjectList[i].value,
          });
        }
        this.getSearchFilterResults(programList, queryString, cb);
      },
      getSearchFilterResults: function(programList, queryString, cb) {
        let results = queryString
            ? programList.filter(this.createStateFilter(queryString))
            : programList;
        //The basic idea is to implement a filter that filters all the data that exists in your input field.
        clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          cb(results);
        }, 1000 * Math.random());
      },
      createStateFilter(queryString) {
        return (program) => {
          return (
              program.value.toLowerCase().indexOf(queryString.toLowerCase()) !== -1
          );
        };
      },
      toggleExemptLanguagesChecked: function() {
        this.$store.state.exemptLanguages = !this.$store.state.exemptLanguages;
        this.exemptLanguagesChecked = this.$store.state.exemptLanguages;
      },
      toggleExemptIrishChecked: function() {
        this.$store.state.exemptIrish = !this.$store.state.exemptIrish;
        this.exemptIrishChecked = this.$store.state.exemptIrish;
      },
      getSubjectName: function(subjectName) {
        return subjectName.toLowerCase().replace(/\b\w/g, l => l.toUpperCase());
      },
      getLevel: function(level) {
        return level.toLowerCase().replace(/\b\w/g, l => l.toUpperCase());
      },
      getGradeLabel: function(grade) {
        return this.gradeMapping[grade.toString().toUpperCase()].toLowerCase().replace(/\b\w/g, l => l.toUpperCase());
      },
      predictCAOPoints: function() {
        let self = this;
        self.irishSelected = false;
        self.mathsSelected = false;
        self.englishSelected = false;
        self.languageSelected = false;
        self.selectedSubjectsList.filter(function(element) {
          if (element.subject_name.toLowerCase() === 'irish' || element.subject_name.toLowerCase() ===
              'irish (foundation level)')
            self.irishSelected = true;
          if (element.subject_name.toLowerCase() === 'mathematics (foundation level)' ||
              element.subject_name.toLowerCase() ===
              'mathematics') {
            self.mathsSelected = true;
          }
          if (element.subject_name.toLowerCase() === 'english') {
            self.englishSelected = true;
          }
          if (self.languages.includes(element.subject_name.toUpperCase())) {
            self.languageSelected = true;
          }
        });
        if (!self.englishSelected) {
          self.$message({
            title: 'Unsuccessful',
            message: 'Please select English to continue',
            type: 'error',
            duration: 3000,
            showClose: true,
          });
          return false;
        }
        if (!self.mathsSelected) {
          self.$message({
            title: 'Unsuccessful',
            message: 'Please select Mathematics to continue',
            type: 'error',
            duration: 3000,
            showClose: true,
          });
          return false;
        }
        if (!self.irishSelected && !self.exemptIrishChecked) {
          self.$message({
            title: 'Unsuccessful',
            message: 'Please select Irish or tick \'Exempt from Irish\' checkbox to continue',
            type: 'error',
            duration: 3000,
            showClose: true,
          });
          return false;
        }
        if (self.irishSelected && self.exemptIrishChecked) {
          self.$message({
            title: 'Unsuccessful',
            message: 'You cannot select Irish as a subject AND select \'Exempt from Irish\'',
            type: 'error',
            duration: 3000,
            showClose: true,
          });
          return false;
        }
        if (self.languageSelected && self.exemptLanguagesChecked) {
          self.$message({
            title: 'Unsuccessful',
            message: 'You cannot select another language as a subject AND select \'Exempt from Languages\'',
            type: 'error',
            duration: 3000,
            showClose: true,
          });
          return false;
        }
        self.setExemptLanguages(self.exemptLanguagesChecked);
        self.setExemptIrish(self.exemptIrishChecked || self.exemptLanguagesChecked);

        let url = self.getBaseUrl() + '/recommendations/predict_cao_points/';
        const type = 'POST';
        let requestData = {
          'exempt_irish': self.exemptIrishChecked || self.exemptLanguagesChecked,
          'exempt_languages': self.exemptLanguagesChecked,
        };
        self.$store.state.loadingScreen = true;
        let updated_points = false;
        $.ajax({
          beforeSend: function(request) {
            request.setRequestHeader('Authorization',
                'Token ' + self.getAuthToken());
          },
          url,
          data: JSON.stringify(requestData),
          type: type,
          contentType: 'application/json',
          success: function(response) {
            if (self.$store.state.recommendationsGenerated) {
              updated_points = true;
            }
            self.setPredictedCAOPoints(response.predicted_score);
            if (updated_points) {
              self.notifyVue('Your predicted CAO points are now ' + response.predicted_score + ', you may need to regenerate Your Skillsvista Fit recommendations', 'success');
            }
            else {
              self.notifyVue('Your predicted CAO points are ' + response.predicted_score, 'success');
            }
            if (self.routedFrom === 'skillsvista_fit') {
              self.setPredictionsSubmitted(true);
              self.$emit('caoPointsSubmitted');
            }
            self.$store.state.loadingScreen = false;
          },
          error: function(errorData) {
            errorData;
            self.notifyVue('Error occurred while predicting points', 'error');
            self.$store.state.loadingScreen = false;
          },
        });
      },
      addEmptySubject: function() {
        let self = this;
        if (self.selectedSubjectsList.length > 0) {
          let lastSubjectSelected = self.selectedSubjectsList[self.selectedSubjectsList.length - 1].subject_name;
          self.subjectOptions = self.subjectOptions.filter(el => el.value !== lastSubjectSelected);
        }
        if (self.currentSubject === 'MATHEMATICS' || self.currentSubject === 'MATHEMATICS (FOUNDATION LEVEL)')
          this.mathsSelected = true;
        if (self.currentSubject === 'IRISH' || self.currentSubject.toLowerCase() === 'IRISH (FOUNDATION LEVEL)')
          this.irishSelected = true;

        self.saveSubject().then(response => {
          self.selectedSubjectsList.push({
            'subject_name': self.currentSubject,
            'level': self.currentLevel,
            'grade': self.currentGrade,
            'id': response,
          });
          self.updateSubjectList();
          self.currentSubject = '';
          self.currentLevel = '';
          self.currentGrade = '';
        }).catch(reject => {
          reject;
          self.updateSubjectList();
        });
      },
      saveSubject: function() {
        let self = this;
        self.$store.state.loadingScreen = true;
        return new Promise(function(resolve, reject) {
          let url = self.getBaseUrl() + '/recommendations/subject/create/';
          let requestData = {
            subject_name: self.currentSubject,
            grade: self.currentGrade,
            level: self.currentLevel,
          };
          const type = 'POST';
          $.ajax({
            beforeSend: function(request) {
              request.setRequestHeader('Authorization',
                  'Token ' + self.getAuthToken());
            },
            url,
            dataType: 'json',
            contentType: 'application/json',
            data: JSON.stringify(requestData),
            type: type,
            success: function(response) {
              self.$store.state.predictedCAOPoints = false;
              self.$store.state.loadingScreen = false;
              if (self.subjectEditID) {
                self.deleteEntry(self.subjectEditID);
              }
              resolve(response['id']);
            },
            error: function(error) {
              error;
              reject();
            },
          });
        });
      },
      editEntry: function(id) {
        let self = this;
        self.subjectEditID = id;
        self.selectedSubjectsList = self.selectedSubjectsList.filter(function(element) {
          if (element.id === id) {
            self.currentLevel = element.level.toUpperCase();
            self.currentSubject = element.subject_name.toUpperCase();
            self.currentGrade = element.grade.toString().toUpperCase();
          }
          return element.id !== id;
        });
      },
      deleteEntry: function(id) {
        let self = this;
        self.$store.state.loadingScreen = true;
        return new Promise(function(resolve, reject) {
          let url = self.getBaseUrl() + `/recommendations/subject/delete/${id}/`;
          let type = 'DELETE';
          $.ajax({
            beforeSend: function(request) {
              request.setRequestHeader('Authorization',
                  'Token ' + self.getAuthToken());
            },
            url: url,
            type: type,
            success: function(response) {
              response;
              if (self.selectedSubjectsList.length === 1) {
                self.selectedSubjectsList.push({
                  'subject_name': '',
                  'level': '',
                  'grade': '',
                });
              }
              let subjectName;
              if (!self.subjectEditID) {
                let filteredIndex = self.selectedSubjectsList.findIndex(element => element.id === id);
                subjectName = self.selectedSubjectsList[filteredIndex].subject_name.toLowerCase();
                
                // Compulsory Subjects need to be added back in the subjectOptions list since we have 
                // that condition in the function: checkIfCorrect
                for(let subject in self.compulsorySubjects){
                  if(subject.subjectName==subjectName || subject.subject_name_other==subjectName){
                    self.subjectOptions.push({value: subjectName.toUpperCase(), text: subjectName.toUpperCase()});
                  }
                }
                if (subjectName === 'irish' || subjectName === 'irish (foundation level)') {
                  self.irishSelected = false;
                }
                if (subjectName === 'mathematics' || subjectName === 'mathematics (foundation level)') {
                  self.mathsSelected = false;
                }
                self.$nextTick(function() {
                  self.selectedSubjectsList.splice(filteredIndex, 1);
                  self.updateSubjectList();
                });
              }
              self.$store.state.predictedCAOPoints = false;
              self.$store.state.loadingScreen = false;
              self.subjectEditID = false;
              resolve();
            },
            error: function(error) {
              reject(error);
              self.$store.state.loadingScreen = false;
            },
          });
        });

      },
      getStatus1: function() {
        if (this.currentSubject === 'LCVP LINK MODULES') {
          return true;
        }
        return this.currentSubject === '' || this.selectedSubjectsList.length === 10;

      },
      getStatus2: function() {
        if (this.currentSubject === 'LCVP LINK MODULES') {
          return false;
        }
        return this.currentSubject === '' || this.currentLevel === '';
      },
      getStatus3: function(subjectItem) {
        if (subjectItem.subject_name.toUpperCase() === 'LCVP LINK MODULES' && subjectItem.grade !== '') {
          return false;
        }
        return (subjectItem.subject_name === '' || subjectItem.level === '' || subjectItem.grade === '');
      },
      getStatus4: function() {
        if (this.currentSubject === 'LCVP LINK MODULES' && this.currentGrade !== '') {
          return false;
        }
        return (this.currentSubject === '' || this.currentLevel === '' || this.currentGrade === '');
      },
    },
  };
</script>


<style scoped>

    .subjectRow {
        padding: 0.4vh 0.4vw;
    }

    .subjectRow:nth-child(even) {
        background-color: #E7EDEB;
    }

    .subjectRow:nth-child(odd) {
        background-color: #ffffff;
    }

    th, td {
        font-family: Lato, serif;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 32px;
        /* identical to box height, or 200% */
        text-align: left;
        color: rgba(0, 0, 0, 0.5);
    }

    .tooltip-container {
      display: flex; /* Use flexbox to align items horizontally */
      align-items: center; /* Align items vertically in the center */
    }

    .toottip{
      font-size: calc(1.2vw + 1.2vh);
      vertical-align: middle; 
      margin-right: 2%;
      color: #17484C;
    }
</style>
