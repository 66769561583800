<template>
    <div>
        <el-dialog
                :append-to-body="true"
                :before-close="emitParentEvent"
                :show-close="true"
                :visible.sync="dialogVisible"
                :width="isMobile() ? '90%':'30%'">
            <div style="margin: 0 1vw;">
                <el-row>
                    <div class="paymentHeadPopover">
                        Unlock Your Skillsvista Fit today for €49.99
                    </div>
                </el-row>
                <el-row>
                    <div style="width: 100%; margin: 0 auto;">
                        <img :src="`${publicPath}img/payment-popover.svg`" alt="no image" style="width: 100%;"/>
                    </div>
                </el-row>
                <el-row style="margin: 1vh 0;">
                    <div class="paymentDesc-1">
                        What do I get when I unlock all Yooni features
                    </div>
                </el-row>
                <el-row style="margin: 1vh 0;">
                    <div class="paymentDesc-2">
                        The Skillsvista Fit assessment analyses your academics, personality and interests to generate
                        personalised college course recommendations and insights through the use of Artificial
                        Intelligence and Data Analytics.
                    </div>
                </el-row>
                <el-row style="margin: 1vh 0;">
                    <div class="paymentDesc-1">
                        Benefits of signing up:
                    </div>
                </el-row>
                <el-row style="margin: 1vh 0;">
                    <el-row :key="index" v-for="(text, index) in paymentOptions">
                        <el-col :span="1" style="display: inline-block; vertical-align: middle;">
                            <div>
                                <img :src="`${publicPath}img/bullet-point.svg`" style="width:40%; margin: 40% 0;">
                            </div>
                        </el-col>
                        <el-col :span="23">
                            <div class="paymentDesc-2">
                                {{text}}
                            </div>
                        </el-col>
                    </el-row>
                </el-row>
                <el-row>
<!--                    <button @click="getUserLoggedIn ? $router.push({path: '/payment/', query: { redirect: $route.fullPath }}):$router.push({path: '/login/', query: { redirect: '/payment/' }})"-->
<!--                            class="black-primary-button takeQuizButton"-->
<!--                            style="width: 100%;">-->
<!--                        Unlock-->
<!--                    </button>-->
                    <button @click="$router.push({path: '/payment/', query: { redirect: $route.fullPath }})"
                            class="black-primary-button takeQuizButton"
                            style="width: 100%;">
                        Unlock
                    </button>
                </el-row>
            </div>

        </el-dialog>
    </div>
</template>

<script>
  export default {
    name: 'PaymentPopover',
    props: ['status'],
    mounted() {
      this.dialogVisible = this.status;
    },
    data: function() {
      return {
        dialogVisible: false,
        paymentOptions: [
          'Course Fit report - your top 5 course recommendations in every major Irish college',
          'Career Fit report -  your top 5 best-fit career areas',
          'Personality Fit report - your personality type, ranked in 6 areas',
          'Your compatibility with over 1,000 careers and college courses',
        ],
      };
    },
    methods: {
      emitParentEvent: function() {
        this.$emit('close', false);
      },
    },
  };
</script>

<style scoped>
    .paymentHeadPopover {
        font-family: Niramit, serif !important;
        font-style: normal;
        font-weight: 500;
        color: #131336;
        word-break: break-word;
        text-align: justify;
    }

    .paymentDesc-1 {
        font-family: Niramit, serif !important;
        font-style: normal;
        font-weight: 500;
        color: #131336;
        opacity: 0.7;
        word-break: break-word;
        text-align: justify;
    }

    .paymentDesc-2 {
        font-family: Lato, serif;
        font-style: normal;
        font-weight: 300;
        color: rgba(0, 0, 0, 0.8);
        word-break: break-word;
        text-align: justify;
    }
</style>