<template>
    <div style="height: 70vh; overflow-y: auto; padding: 16px;">
        <el-row>
            <el-col :span="12" class="yooni-fit-head-3 head-3">
                Your Personality fit!
            </el-col>
        </el-row>
        <el-row>
            <el-col class="yooni-fit-head-1 description-1">
                Read the two activities below. Then choose which you
                would prefer by clicking on the selection device.
            </el-col>
        </el-row>
        <el-carousel :autoplay="false" arrow="always" class="web-carousel"
                     ref="carousel"
                     style="margin: 16px auto; width: 60%;"
                     v-if="!isMobile()">
            <el-carousel-item :key="index" :loop="false"
                              indicator-position="none"
                              style="padding: 2vh 1vw;"
                              v-for="(item, index) in getPersonalityTestQuestions">
                <el-row>
                    <el-col :span="8" style="text-align: justify; margin-bottom: 2vw;">
                        <div class="text1">Would you like to...</div>
                    </el-col>
                    <el-col :offset="10" :span="6" style="text-align: right;">
                        <div class="text2">Question {{index+1}} of {{totalQuestions}}</div>
                    </el-col>
                </el-row>

                <el-row style="text-align: justify">
                    <el-col :span="8" class="question-class">
                        {{item.question}}
                    </el-col>
                    <el-col :span="8" style="text-align: center; line-height: 3vw; font-size: 1.5vw;">
                        or
                    </el-col>
                    <el-col :span="8" class="question-class">
                        {{item.related_question}}
                    </el-col>
                </el-row>
                <br>
                <el-row style="margin-top: 3vh;">
                    <el-col :offset="1" :span="6">
                        <el-button :class="{'success': item.score==='q1_really_prefer'}"
                                   @click="setAnswer(index, 'q1_really_prefer')"
                                   class="question-options much-prefer">
                            Really Prefer
                        </el-button>
                    </el-col>
                    <el-col :offset="2" :span="6">
                        <el-button :class="{'success': item.score==='like_both'}"
                                   @click="setAnswer(index, 'like_both')"
                                   class="question-options much-prefer">
                            Like Both
                        </el-button>
                    </el-col>
                    <el-col :offset="2" :span="6">
                        <el-button :class="{'success': item.score==='q2_really_prefer'}"
                                   @click="setAnswer(index, 'q2_really_prefer')"
                                   class="question-options much-prefer">
                            Really Prefer
                        </el-button>
                    </el-col>
                </el-row>
                <el-row style="margin-top: 4vh;">
                    <el-col :offset="1" :span="6">
                        <el-button :class="{'success': item.score==='q1_prefer'}"
                                   @click="setAnswer(index, 'q1_prefer')" class="question-options much-prefer">
                            Prefer
                        </el-button>
                    </el-col>
                    <!--                    <el-col :span="3">-->
                    <!--                        <el-button :class="{'success': item.score==='no_preference'}"-->
                    <!--                                   @click="setAnswer(index, 'no_preference')" class="question-options neutral">Neutral-->
                    <!--                        </el-button>-->
                    <!--                    </el-col>-->
                    <el-col :offset="2" :span="6">
                        <el-button :class="{'success': item.score==='dislike_both'}"
                                   @click="setAnswer(index, 'dislike_both')" class="question-options much-prefer">
                            Dislike Both
                        </el-button>
                    </el-col>
                    <el-col :offset="2" :span="6">
                        <el-button :class="{'success': item.score==='q2_prefer'}"
                                   @click="setAnswer(index, 'q2_prefer')" class="question-options much-prefer">
                            Prefer
                        </el-button>
                    </el-col>
                </el-row>
                <el-button @click="previousSlide(index)" circle
                           icon="el-icon-back"
                           style="position: absolute; top: 50%; left: 2.5%; color: #B0B0CC; border: 2px solid #B0B0CC;"
                           v-if="index > 0"></el-button>
            </el-carousel-item>
        </el-carousel>

        <el-carousel :autoplay="false" arrow="always" class="mobile-carousel" ref="carousel_mobile"
                     style="margin: 0 auto; padding: 2vh 0; width: 95%;"
                     v-else>
            <el-carousel-item :key="index" :loop="false"
                              indicator-position="none"
                              style="padding: 2vh 1vw; height: 100%;"
                              v-for="(item, index) in getPersonalityTestQuestions">
                <div style="padding: 1vh 3vw;">
                    <el-row style="height: 5vh;">
                        <el-col :span="18" style="text-align: justify;">
                            <span class="text1">Would you like to...</span>
                        </el-col>
                        <el-col :span="6" style="text-align: right;">
                            <span class="text2">Question {{index+1}} of {{totalQuestions}}</span>
                        </el-col>
                    </el-row>

                    <el-row style="margin: 3vh 0; height: 5vh;">
                        <el-col class="question-class">
                            {{item.question}}
                        </el-col>
                    </el-row>

                    <el-row>
                        <el-col :offset="4" :span="16">
                            <el-button :class="{'success': item.score==='q1_really_prefer'}"
                                       :type="item.score==='q1_really_prefer' ? 'success':''"
                                       @click="setAnswer(index, 'q1_really_prefer')" class="question-options much-prefer">
                                Really Prefer
                            </el-button>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :offset="4" :span="16">
                            <el-button :class="{'success': item.score==='q1_prefer'}"
                                       @click="setAnswer(index, 'q1_prefer')" class="question-options much-prefer">
                                Prefer
                            </el-button>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :offset="4" :span="16">
                            <el-button :class="{'success': item.score==='like_both'}"
                                       @click="setAnswer(index, 'like_both')" class="question-options much-prefer"
                                       style="width: 100%;">
                                Like Both
                            </el-button>
                        </el-col>
                    </el-row>
                    <!--                    <el-row>-->
                    <!--                        <el-col :offset="1" :span="4">-->
                    <!--                            <el-button :class="{'success': item.score==='no_preference'}" circle-->
                    <!--                                       @click="setAnswer(index, 'no_preference')" class="question-options neutral">-->
                    <!--                                Neutral-->
                    <!--                            </el-button>-->
                    <!--                        </el-col>-->
                    <!--                    </el-row>-->
                    <el-row>
                        <el-col :offset="4" :span="16">
                            <el-button :class="{'success': item.score==='dislike_both'}"
                                       @click="setAnswer(index, 'dislike_both')" class="question-options much-prefer"
                                       style="width: 100%;">
                                Dislike Both
                            </el-button>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :offset="4" :span="16">
                            <el-button :class="{'success': item.score==='q2_prefer'}"
                                       @click="setAnswer(index, 'q2_prefer')" class="question-options much-prefer">
                                Prefer
                            </el-button>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :offset="4" :span="16">
                            <el-button :class="{'success': item.score==='q2_really_prefer'}"
                                       @click="setAnswer(index, 'q2_really_prefer')" class="question-options much-prefer">
                                Really Prefer
                            </el-button>
                        </el-col>
                    </el-row>
                    <el-row style="margin: 3vh 0; height: 5vh;">
                        <el-col class="question-class">
                            {{item.related_question}}
                        </el-col>
                    </el-row>
                    <el-button @click="previousSlide(index)" circle
                               icon="el-icon-back"
                               style="position: absolute; top: 50%; left: 2.5%; color: #B0B0CC; border: 1px solid #B0B0CC;"
                               v-if="index > 0"></el-button>
                </div>
            </el-carousel-item>
        </el-carousel>

        <el-row :style="isMobile() ? {'width': '95%'}: {'width': '60%'}" style="margin: auto;padding: 0 1vw;"
                v-if="showSubmit">
            <el-col :lg="{span: 4, offset: 20}" :md="{span: 4, offset: 20}" :sm="{span: 24}" :xl="{span: 4, offset: 20}"
                    :xs="{span: 24}">
                <el-button @click="submitPersonalityTest()" class="submitQuestions careerTestNavButtons">
                    Submit
                </el-button>
            </el-col>
        </el-row>

    </div>

</template>

<script>
  import {store} from '../../store';
  import $ from 'jquery';
  import yooniFitMixins from '../mixins/yooni_fit_mixins';

  export default {
    components: {},
    mixins: [yooniFitMixins],
    name: 'WebPersonalityFitTest',
    mounted() {
      this.personalityTestQuestions = store.state.personalityTestQuestions;
      $('.el-carousel__indicators').remove();
      $('.el-carousel__arrow--right').remove();
      $('.el-carousel__arrow').remove();
      // $('.el-carousel__container').
      //     append(
      //         '<el-button class="el-carousel__arrow el-carousel__arrow--left" type="primary" icon="el-icon-edit" circle></el-button>');
      // $('.el-carousel__arrow--left').html("<i class='fa fa-arrow-left' aria-hidden='true'></i>")
    },
    computed: {
      getPersonalityTestQuestions: function() {
        return store.state.personalityTestQuestions;
      },

    },
    methods: {
      previousSlide: function(index) {
        if (this.isMobile()) {
          this.$refs.carousel_mobile.setActiveItem(index - 1);
        } else {
          this.$refs.carousel.setActiveItem(index - 1);
        }
      },
      setAnswer: function(index, value) {
        this.$nextTick(function() {
          this.personalityTestQuestions[index].score = value;
          if (this.isMobile()) {
            if (index !== 59)
              this.$refs.carousel_mobile.setActiveItem(index + 1);
          } else {
            if (index !== 59)
              this.$refs.carousel.setActiveItem(index + 1);
          }
        });
        let filteredData = this.personalityTestQuestions.filter(function(value) {
          return value.score === '';
        });
        if (filteredData.length === 0) {
          this.showSubmit = true;
        }
      },
    },
    data: function() {
      return {
        showSubmit: false,
        currentQuestion: 1,
        totalQuestions: 60,
        personalityTestQuestions: [],
      };
    },
  };
</script>

<style>
    .el-button.is-circle {
        padding: 1vh !important;
    }

    .el-icon-back {
        font-weight: 900 !important;
        font-size: 18px;
    }

    .web-carousel > .el-carousel__container > .el-carousel__item > .el-carousel__arrow--left {
        border: 2px solid #B0B0CC !important;
        background-color: white !important;
        transform: matrix(-1, 0, 0, 1, 0, 0);
    }

    .el-icon-arrow-left {
        /*font-size: 1vw !important;*/
        color: #B0B0CC !important;
        font-weight: bolder !important;
    }

    .web-carousel > .el-carousel__container > .el-carousel__item {
        background-color: #fff;
        border: 1px solid #DEDEDE;
    }

    .clearfix:before,
    .clearfix:after {
        display: table;
        content: "";
    }

    .clearfix:after {
        clear: both
    }

    /*.el-card__header {*/
    /*    border-bottom: none !important;*/
    /*    padding: 0 2vw 3vh !important;*/

    /*}*/

    /*.el-button:hover {*/
    /*    border: 1px solid #929292;*/
    /*    background: #929292 !important;*/
    /*    color: white !important;*/
    /*}*/

    .web-carousel > .el-carousel__container {
        height: 40vh !important;
    }

    .mobile-carousel > .el-carousel__container {
        height: 75vh !important;
    }
</style>
