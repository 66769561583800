<template>
    <div style="overflow-y: auto; height: 70vh; overflow-x: hidden;">
        <el-row style="padding: 1vw;">
            <el-col :span="12" class="yooni-fit-head-3 head-3" style = "color : white">
                <span v-if="testType==='career_fit'">
                    Your Career fit!
                </span>
                <span v-else-if="testType==='personality_fit'">
                    Your Personality fit!
                </span>
                <span v-else>
                    Your Academic fit!
                </span>
            </el-col>
        </el-row>
        <el-row style="padding: 1vw;">
            <el-card class="box-card">
                <el-row style="padding: 2vh 1vw;">
                    <el-col :lg="5" :md="5" :sm="24" :xl="5" :xs="24">

                        <div>
                            <img :src="`${publicPath}img/yooni_fit/${testType}.svg`" alt=""
                                 style="width: 100%;"/>
                        </div>
                    </el-col>
                    <el-col :lg="19" :md="19" :sm="24" :xl="19" :xs="24" class="main-col-1">
                        <el-row>
                            <el-col :lg="20" :md="20" :sm="24" :xl="20" :xs="24">
                                <div class="interest-fit-head">
                                    {{data.heading}}
                                </div>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :lg="20" :md="20" :sm="24" :xl="20" :xs="24">
                                <div class="interest-fit-desc">
                                    {{data.description}}
                                </div>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :lg="4" :md="4" :sm="24" :xl="4" :xs="24">
                                <button @click="showPaymentWall" class="black-primary-button takeQuizButton"
                                        style="width: 100%;"
                                        v-if="!getIsPremiumUser">
                                    Unlock
                                </button>
                                <button @click="testType === 'points_predict'? startTest(data.type, 'yooni_fit') :startTest(data.type)"
                                        class="black-primary-button takeQuizButton"
                                        style="width: 100%;"
                                        v-else-if="testType !== 'career_fit_test_completed' && testType !== 'personality_fit_test_completed'">
                                    Take Quiz
                                </button>
                            </el-col>
                        </el-row>
                    </el-col>
                </el-row>
            </el-card>
        </el-row>
    </div>
</template>

<script>
  import yooniFitMixins from '../../mixins/yooni_fit_mixins';

  export default {
    mixins: [yooniFitMixins],
    components: {},
    props: ['testType', 'data'],
    mounted() {
      // this.showTakeQuiz = true;
    },
    data: function() {
      return {
        showDisclaimer: false,
        termConditionsChecked: false,
        showCareerFitQuiz: false,
        showPersonalityFitQuiz: false,
        showTakeQuiz: false,
        interestFitTestCompleted: false,
        careerFitTestCompleted: true,
      };
    },
    methods: {},
    name: 'WebPersonalityCareerFit',
  };
</script>

<style scoped>

</style>