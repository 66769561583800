<template>
    <div :style="!isMobile()?{'height': '97.5vh'}: {'height': '90vh'}"
         style="overflow-y: auto; overflow-x: hidden;">
        <el-row :style="!isMobile()?{'padding': '0 5vw', 'width': '90%', 'margin': '5vh auto'}: {'margin': '5vh auto'}">
            <el-col :lg="12" :md="24" :sm="24" :xl="12" :xs="24">
                <el-row style="margin: 1vh auto;width: 80%;">
                    <img :src="`${publicPath}img/payment-1.jpg`" style="width: 100%;">
                </el-row>
                <el-row style="padding: 1vh 0; width: 80%; margin: 0 auto;">
                    <el-col>
                        <div class="paymentHead">
                            What's included in Your Skillsvista Fit?
                        </div>
                        <ul>
                            <li class="paymentDesc">
                                Course Fit report - your top 5 course recommendations in every major Irish college
                            </li>
                            <li class="paymentDesc">
                                Career Fit report - your top 5 best-fit career areas
                            </li>
                            <li class="paymentDesc">
                                Personality Fit report - your personality type, ranked in 6 areas
                            </li>
                            <li class="paymentDesc">
                                Your compatibility with over 1,000 careers and college courses
                            </li>
                        </ul>
                    </el-col>
                </el-row>
                <el-row class="paymentInfo" style="width: 80%; margin: 0 auto;">
                    <el-row>
                        <el-col>
                            <div class="paymentHead">Total Payment</div>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="21">
                            <div class="paymentDesc">Yooni Fit Assessment Software</div>
                        </el-col>
                        <el-col :span="3">
                            <div v-if="getOriginalAmount"
                                 :class="{strikethrough: paymentAmount !== getOriginalAmount}">
                                €{{ getOriginalAmount }}
                            </div>
                        </el-col>
                    </el-row>
                    <el-row v-if="paymentAmount !== getOriginalAmount">
                        <el-col :span="7" style="height: 5vh; " v-if="appliedCouponCode !== ''">
                            <div class="paymentDesc-3" style="line-height: 3.3vh;">Discount Applied:</div>
                        </el-col>
                        <el-col :span="1" style="height: 5vh; " v-if="appliedCouponCode !== ''">
                            <el-tag @close="removeCoupon" :closable="configMessage === ''">
                                {{ appliedCouponCode }}
                            </el-tag>
                        </el-col>
                        <el-col :offset="13" :span="3" style="height: 5vh; ">
                            <div style="line-height: 3.3vh;transform: scale(1.2); font-weight: 900; color: red;"
                                 v-if="paymentAmount !== originalAmount">
                                €{{ paymentAmount }}
                            </div>
                        </el-col>
                    </el-row>
                </el-row>

                <el-row style="padding: 3vh 0 1vh; width: 80%; margin: 0 auto;"
                        v-if="!isMobile() && configMessage === ''">
                    <el-col :xl="{span: 18}" :lg="{span: 18}" :md="{span: 15}" :sm="{span: 15}"
                            :xs="{span: 16}">
                        <el-input class="discount-input" placeholder="Discount code" v-model="couponCode"></el-input>
                    </el-col>
                    <el-col :xl="{span: 4, offset: 2}" :lg="{span: 4, offset: 2}" :md="{span: 8}" :sm="{span: 8}"
                            :xs="{span: 8}">
                        <el-button @click="applyCoupon"
                                   class="mobile-signupButton findoutmore-button"
                                   style="height: 4vh !important; padding: 0 !important;"
                                   type="button">
                            Apply
                        </el-button>
                    </el-col>
                </el-row>
                <el-row style="padding: 3vh 0 1vh; width: 80%; margin: 0 auto;"
                        v-if="!isMobile() && configMessage !== ''">
                    <div style="font-size: 10px; text-align: left; color: red;">
                        Note: No additional coupons can be applied during this period.
                    </div>
                </el-row>
                <el-row style="padding: 0vh 2vw; width: 90%; margin: 0 auto;" v-if="!isMobile()">
                    <el-col>
                        <div style="color: red;text-align: left">
                            {{ paymentError }}
                        </div>
                    </el-col>
                </el-row>
            </el-col>
            <el-col :lg="12" :md="24"
                    :sm="24"
                    :style="!isMobile() ? {'padding': '3vh 2vw'}:{'padding': '3vh 2vw'}" :xl="12" :xs="24"
                    style="margin: 1vh 0; background-color: #ffffff;">
                <el-row style="width: 5vw; margin: 0 auto;" v-if="!isMobile()">
                    <img :src="`${publicPath}img/yooni-sidebar.png`" class="yooni-logo" style="width: 100%;">
                </el-row>
                <el-row>
                    <el-col :offset="1" :span="22">
                        <div class="paymentHead-1">
                            Discover your Dream College Course
                        </div>
                    </el-col>
                </el-row>
                <el-row style="margin-top: 2vh;" v-if="isMobile()">
                    <el-col :span="12" v-for="(student, index) in students" :key="index">
                        <el-row>
                            <img :src="`${publicPath}img/5-stars.svg`" alt="" style="width: 50%;"/>
                        </el-row>
                        <el-row class="paymentDesc" style="text-align: center !important;">
                            {{ student.name }}, {{ student.year }}
                        </el-row>
                        <el-row class="paymentDesc" style="text-align: center !important;">
                            {{ student.school }}
                        </el-row>
                    </el-col>
                </el-row>
                <br>
                <el-card type="border-card"
                         style="width: 90%;
                            margin: 2vh auto;
                            padding: 2vh 0;
                            background: #FFF;
                            border: 1px solid #DCDFE6;
                            box-shadow: 0 2px 4px 0 rgba(0,0,0,.12), 0 0 6px 0 rgba(0,0,0,.04);"
                         v-if="getUserLoggedIn">
                    <el-row :gutter="20">
                        <el-col :lg="{span: 10, offset:1}" :md="{span: 10, offset:1}" :sm="{span: 11, offset:1}"
                                :xl="{span: 10, offset:1}"
                                :xs="{span: 11, offset:1}">
                            <el-input placeholder="Your first name" v-model="customerFirstName"></el-input>
                        </el-col>
                        <el-col :lg="{span: 10, offset:1}" :md="{span: 10, offset:1}" :sm="{span: 11}"
                                :xl="{span: 10, offset:1}"
                                :xs="{span: 11}">
                            <el-input placeholder="Your last name" v-model="customerLastName"></el-input>
                        </el-col>
                    </el-row>
                    <br>
                    <el-row v-if="getUserLoggedIn">
                        <el-col :lg="21" :md="21" :offset="1" :sm="22" :xl="21" :xs="22">
                            <el-input placeholder="Your email address" v-model="customerEmail"></el-input>
                        </el-col>
                    </el-row>
                </el-card>
                <el-row v-if="!getUserLoggedIn" style="width: 90%; margin: 0 auto;">
                    <el-tabs id="loginTabs" type="border-card" v-model="selectedLoginTab" @tab-click="resetVariables">
                        <el-tab-pane label="Login" name="Login">
                            <el-row>
                                <el-row style=" padding: 0 1vw;">
                                    <el-row style="margin: 2vh 0;">
                                        <el-input placeholder="Your Email" v-model="emailId"></el-input>
                                    </el-row>
                                    <el-row style="margin: 2vh 0;">
                                        <el-input placeholder="Your Password" show-password type="password"
                                                  v-model="password"></el-input>
                                    </el-row>
                                    <!--                                    <el-row style="margin: 2vh 0;">-->
                                    <!--                                        <el-col :offset="16" :span="8">-->
                                    <!--                                            <div class="forgot-password">Forgot Password ?</div>-->
                                    <!--                                        </el-col>-->
                                    <!--                                    </el-row>-->
                                    <el-row v-if="mainError !== '' && mainError !== undefined">
                                        <div style="font-size: 10px; color: red; text-align: left">
                                            {{ mainError }}
                                        </div>
                                    </el-row>
                                    <el-row style="margin: 2vh 0; font-size: 10px;">
                                        <el-col :offset="8" :span="8">Or Sign in Using</el-col>
                                    </el-row>
                                    <el-row style="margin: 1vh 0;">
                                        <el-col :span="4" :offset="8">
                                            <!--                                            <el-button-->
                                            <!--                                                    @click="authenticate('facebook', {'provider': 'facebook'})"-->
                                            <!--                                                    class="btn-sign fb">Facebook-->
                                            <!--                                            </el-button>-->
                                            <img :src="`${publicPath}img/facebook.svg`" alt="" style="cursor:pointer;"
                                                 @click="authenticate('facebook', {'provider': 'facebook'}, true)">
                                        </el-col>
                                        <el-col :span="4">
                                            <!--                                            <el-button-->
                                            <!--                                                    @click="authenticate('google', {'provider': 'google-oauth2'})"-->
                                            <!--                                                    class="btn-sign gg">-->
                                            <!--                                                Google-->
                                            <!--                                            </el-button>-->
                                            <img :src="`${publicPath}img/google.svg`" alt="" style="cursor:pointer;"
                                                 @click="authenticate('google', {'provider': 'google-oauth2'}, true)">
                                        </el-col>
                                    </el-row>
                                </el-row>
                            </el-row>
                        </el-tab-pane>
                        <el-tab-pane label="Register" name="Register">
                            <el-row>
                                <el-row style="padding: 0 1vw;">
                                    <el-row style="margin: 2vh 0 0;">
                                        <el-input :autocomplete="'on'" clearable for="name" placeholder="Your Full Name"
                                                  v-model="fullName"></el-input>
                                    </el-row>
                                    <el-row v-if="errorsFullName !== '' && errorsFullName !== undefined">
                                        <div style="font-size: 10px; color: red; text-align: left">
                                            {{ errorsFullName }}
                                        </div>
                                    </el-row>
                                    <el-row style="margin: 2vh 0 0;">
                                        <el-input :autocomplete="'on'" clearable for="email" placeholder="Your Email ID"
                                                  type="text"
                                                  v-model="emailId"></el-input>
                                    </el-row>
                                    <!--                            <el-row style="margin: 2vh 0;">-->
                                    <!--                                <el-input clearable placeholder="Your School" type="password" v-model="school"></el-input>-->
                                    <!--                            </el-row>-->
                                    <el-row v-if="errorsEmail !== '' && errorsEmail !== undefined">
                                        <div style="font-size: 10px; color: red; text-align: left">
                                            {{ errorsEmail }}
                                        </div>
                                    </el-row>
                                    <el-row style="margin: 2vh 0 0;">
                                        <el-input :autocomplete="'on'" clearable for="password"
                                                  placeholder="Your Password"
                                                  show-password
                                                  type="password" v-model="password"></el-input>
                                    </el-row>
                                    <el-row v-if="errorsPassword !== '' && errorsPassword !== undefined">
                                        <div style="font-size: 10px; color: red; text-align: left">
                                            {{ errorsPassword }}
                                        </div>
                                    </el-row>
                                    <el-row style="margin: 2vh 0 0;">
                                        <el-input :autocomplete="'on'" clearable placeholder="Confirm Password"
                                                  type="password"
                                                  v-model="confirmPassword"></el-input>
                                    </el-row>
                                    <el-row v-if="errorsConfirmPassword !== '' && errorsConfirmPassword !== undefined">
                                        <div style="font-size: 10px; color: red; text-align: left">
                                            {{ errorsConfirmPassword }}
                                        </div>
                                    </el-row>
                                    <el-row v-if="mainError !== '' && mainError !== undefined">
                                        <el-col :key="type" v-for="(error, type) in mainError">
                                            <ul>
                                                <li style="font-size: 12px; color: red; text-align: left"
                                                    v-if="typeof error === 'string'">
                                                    {{ error }}
                                                </li>
                                                <li v-for="(detailError, index1) in error" :key="index1"
                                                    style="font-size: 12px; color: red; text-align: left"
                                                    v-else>
                                                    {{ detailError }}
                                                </li>
                                            </ul>
                                        </el-col>
                                    </el-row>
                                    <el-row style="margin: 2vh 0; font-size: 10px;">
                                        <el-col :offset="8" :span="8">Or Sign Up Using</el-col>
                                    </el-row>
                                    <el-row style="margin: 0;">
                                        <el-col :span="6" :offset="6">
                                            <!--                                            <el-button-->
                                            <!--                                                    @click="authenticate('facebook', {'provider': 'facebook'})"-->
                                            <!--                                                    class="btn-sign fb">Facebook-->
                                            <!--                                            </el-button>-->
                                            <img :src="`${publicPath}img/facebook.svg`" alt="" style="cursor:pointer;"
                                                 @click="authenticate('facebook', {'provider': 'facebook'}, true)">
                                        </el-col>
                                        <el-col :span="6">
                                            <!--                                            <el-button-->
                                            <!--                                                    @click="authenticate('google', {'provider': 'google-oauth2'})"-->
                                            <!--                                                    class="btn-sign gg">-->
                                            <!--                                                Google-->
                                            <!--                                            </el-button>-->
                                            <img :src="`${publicPath}img/google.svg`" alt="" style="cursor:pointer;"
                                                 @click="authenticate('google', {'provider': 'google-oauth2'}, true)">
                                        </el-col>
                                    </el-row>
                                </el-row>

                            </el-row>
                        </el-tab-pane>
                    </el-tabs>
                </el-row>
                <!--                <el-row>-->
                <!--                    <el-col :span="22" :offset="1" class="paymentDesc">-->
                <!--                        <input type="checkbox" v-model="checked" style="margin-right: 5px; font-size: 20px;"/>-->
                <!--                        <span class="terms-condition">&nbsp;&nbsp;&nbsp;I agree to Yooni’s <span-->
                <!--                                style="color: #9698D6; cursor: pointer;">Terms & Conditions</span></span>-->
                <!--                    </el-col>-->
                <!--                </el-row>-->
                <el-card type="border-card"
                         style="width: 90%; margin: 2vh auto; background: #FFF; border: 1px solid #DCDFE6; box-shadow: 0 2px 4px 0 rgba(0,0,0,.12), 0 0 6px 0 rgba(0,0,0,.04);">
                    <el-row v-if="paymentAmount !== 0">
                        <el-col :offset="1" :span="22">
                            <div class="paymentHead">Payment Information</div>
                        </el-col>
                    </el-row>
                    <el-row class="form-group" v-if="paymentAmount !== 0">
                        <el-col :offset="1" :span="22">
                            <el-row style="border: 1px solid #DCDCDC; border-radius: 5px;">
                                <el-col :span="16">
                                    <el-input @keypress.native="isNumber($event)"
                                              @focus="setUpAnalytics"
                                              class="cardNumber" id="card-number" maxlength="16"
                                              placeholder="Card number"
                                              required
                                              type="text"
                                              v-model="card.number">
                                        <img :src="`${publicPath}img/card_icon.svg`" alt="" slot="prefix"
                                             style="margin-top: 40%;">
                                    </el-input>
                                </el-col>
                                <el-col :span="4">
                                    <label>
                                        <input @keypress="isNumber($event)" class="month-year-text" maxlength="5"
                                               placeholder="MM/YY"
                                               required
                                               type="text"
                                               v-model="card.exp">
                                    </label>
                                </el-col>
                                <el-col :span="3">
                                    <label>
                                        <input @keypress="isNumber($event)" class="month-year-text" maxlength="4"
                                               placeholder="CVV"
                                               required
                                               type="text"
                                               v-model="card.cvc">
                                    </label>
                                </el-col>
                            </el-row>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :offset="1" :span="22">
                            <button @click.prevent="validate" class="black-primary-button completeOrderBtn"
                                    style="width: 100%; height: 5vh; margin: 3% 0;"
                                    v-if="paymentAmount !== 0">
                                Complete Order
                            </button>
                            <button @click.prevent="validateWithoutCard" class="black-primary-button completeOrderBtn"
                                    style="width: 100%; height: 5vh;"
                                    v-else>
                                Proceed
                            </button>
                        </el-col>
                    </el-row>
                    <el-row v-if="paymentAmount !== 0">
                        <el-col :offset="9" :span="6">
                            <img :src="`${publicPath}img/cards-img.png`" alt="" style="width: 100%;"/>
                        </el-col>
                    </el-row>
                </el-card>
                <el-row>
                    <ul>
                        <li :key="error" class="error-class" v-for="error in errors">
                            {{ error }}
                        </li>
                    </ul>
                </el-row>
                <el-row style="margin-top: 2vh;" v-if="!isMobile()">
                    <el-col :span="12" v-for="(student, index) in students" :key="index">
                        <el-row>
                            <img :src="`${publicPath}img/5-stars.svg`" alt="" style="width: 50%;"/>
                        </el-row>
                        <el-row class="paymentDesc" style="text-align: center !important;">
                            {{ student.name }}, {{ student.year }}
                        </el-row>
                        <el-row class="paymentDesc" style="text-align: center !important;">
                            {{ student.school }}
                        </el-row>
                    </el-col>
                </el-row>
                <el-row style="padding: 1vh 0; width: 90%; margin: 0 auto;" v-if="isMobile() && configMessage === ''">
                    <el-col :xl="{span: 14, offset: 1}" :lg="{span: 14, offset: 1}" :md="{span: 15}" :sm="{span: 15}"
                            :xs="{span: 16}">
                        <el-input class="discount-input" placeholder="Discount code" v-model="couponCode"></el-input>
                    </el-col>
                    <el-col :xl="{span: 4, offset: 2}" :lg="{span: 4, offset: 2}" :md="{span: 8}" :sm="{span: 8}"
                            :xs="{span: 8}">
                        <el-button @click="applyCoupon"
                                   class="mobile-signupButton findoutmore-button"
                                   style="line-height: calc(1vh + 1vw) !important;"
                                   type="button">
                            Apply
                        </el-button>
                    </el-col>
                </el-row>
                <el-row style="padding: 1vh 0; width: 90%; margin: 0 auto;"
                        v-if="isMobile() && configMessage !== ''">
                    <div style="font-size: 10px; text-align: left; color: red;">
                        Note: No additional coupons can be applied during this period.
                    </div>
                </el-row>
                <el-row style="padding: 1vh 0; width: 90%; margin: 0 auto;" v-if="isMobile()">
                    <el-col>
                        <div style="color: red;text-align: left">
                            {{ paymentError }}
                        </div>
                    </el-col>
                </el-row>
            </el-col>
        </el-row>
    </div>
</template>
<script>
import {mapActions} from 'vuex';
import PersonalInformation from '../../main_components/PersonalInformation';
import LoginInformation from '../../main_components/LoginInformation';
import SignUpInformation from '../../main_components/SignUpInformation';

export default {
    components: {SignUpInformation, LoginInformation, PersonalInformation},
    beforeMount() {
        let self = this;
        // if (!self.$store.getters.getUserLoggedIn) {
        //   self.$router.push('/');
        // }
        if (self.$store.getters.getIsPremiumUser) {
            self.$router.push('/');
        }
        self.checkUserLoggedIn().then(response => {
            if (response.user_type === 2) {
                self.$router.push('/counsellor/home/');
            }
        }).catch(error => {
            error;
        });
        self.getPaymentInformation();
    },
    mounted: function () {
        let self = this;
        this.sendAnalytics();
        let config;
        if (this.isProduction)
            config = require('../../frontend-prod-config.json');
        else
            config = require('../../frontend-local-config.json');
        this.stripePublishableKey = config.stripePublishableKey;
        // this.$analytics.fbq.event('ViewContent');
        self.$analytics.fbq.event('InitiateCheckout', {
            name: self.$store.state.studentFullName,
            email: self.$store.state.studentEmail,
        });
    },
    data: function () {
        return {
            configMessage: '',
            students: [
                {
                    'name': 'Hannah',
                    'year': '6th year',
                    'school': 'St. Angelas',
                }, {
                    'name': 'James',
                    'year': '6th year',
                    'school': 'Coachford',
                }, {
                    'name': 'Laura',
                    'year': '5th year',
                    'school': 'St. Mary\'s',
                }, {
                    'name': 'Tiernan',
                    'year': '6th year',
                    'school': 'Bandon Grammar',
                }],
            emailId: '',
            password: '',
            mainError: '',
            showLogin: true,
            showDialogue: false,
            checked: true,
            card: {
                number: '',
                cvc: '',
                exp: '',
            },
            errors: [],
            customerFirstName: '',
            customerLastName: '',
            customerEmail: '',
            customerPhone: '',
            stripePublishableKey: '',
            stripeCheck: false,
            loadingScreen: false,
            paymentAmount: false,
            originalAmount: false,
            couponCode: '',
            appliedCouponCode: '',
            paymentError: '',
            selectedLoginTab: 'Login',
            confirmPassword: '',
            fullName: '',
            errorsFullName: '',
            errorsEmail: '',
            errorsPassword: '',
            errorsConfirmPassword: '',
        };
    },
    computed: {
        getOriginalAmount: function () {
            return this.originalAmount;
        },
    },
    methods: {
        ...mapActions(['setIsPremiumUser']),
        resetVariables: function () {
            this.errorsFullName = '';
            this.errorsEmail = '';
            this.errorsPassword = '';
            this.errorsConfirmPassword = '';
            this.fullName = '';
            this.emailId = '';
            this.password = '';
            this.mainError = '';
            this.errors = '';
            this.card = {
                number: '',
                cvc: '',
                exp: '',
            };
        },
        setUpAnalytics: function () {
            let self = this;
            self.$analytics.fbq.event('AddPaymentInfo', {
                name: self.$store.state.studentFullName,
                email: self.$store.state.studentEmail,
            });
        },
        removeCoupon: function () {
            let self = this;
            self.paymentError = '';
            let url = self.getBaseUrl() + '/accounts/coupon/remove/';
            self.$store.state.loadingScreen = true;
            $.ajax({
                beforeSend: function (request) {
                    if (self.$store.state.userLoggedIn)
                        request.setRequestHeader('Authorization', 'Token ' + self.getAuthToken());
                },
                url: url,
                type: 'POST',
                contentType: 'application/json',
                data: JSON.stringify({'coupon': self.appliedCouponCode}),
                success: function (response) {
                    if (self.$store.state.userLoggedIn)
                        self.getPaymentInformation();
                    else {
                        self.paymentAmount = response['payable_amount'];
                        self.originalAmount = response['original_amount'];
                        self.appliedCouponCode = '';
                    }
                    self.$store.state.loadingScreen = false;
                },
                error: function (error) {
                    if (error.responseJSON['message'] !== undefined)
                        self.paymentError = error.responseJSON['message'];
                    else {
                        self.paymentError = error;
                    }
                    self.$store.state.loadingScreen = false;
                },
            });
        },
        getPaymentInformation: function () {
            let self = this;
            self.paymentError = '';
            self.$store.state.loadingScreen = true;
            $.ajax({
                beforeSend: function (request) {
                    if (self.getAuthToken() !== '')
                        request.setRequestHeader('Authorization', 'Token ' + self.getAuthToken());
                },
                url: self.getBaseUrl() + '/accounts/pd/',
                type: 'GET',
                success: function (response) {
                    if (response['message'] === 'Login required') {
                        self.$router.push('/');
                    } else {
                        self.paymentAmount = response['dis'];
                        self.originalAmount = response['ogi'];
                        self.configMessage = response['config_message'];
                        self.appliedCouponCode = response['coupon'];
                    }
                    self.$store.state.loadingScreen = false;
                },
                error: function (error) {
                    error;
                    self.paymentAmount = 49.99;
                    self.originalAmount = 49.99;
                    self.$store.state.loadingScreen = false;
                },
            });
        },
        applyCoupon: function () {
            let self = this;
            self.paymentError = '';
            let url = self.getBaseUrl() + '/accounts/coupon/apply/';
            self.$store.state.loadingScreen = true;
            $.ajax({
                beforeSend: function (request) {
                    if (self.$store.state.userLoggedIn)
                        request.setRequestHeader('Authorization', 'Token ' + self.getAuthToken());
                },
                url: url,
                type: 'POST',
                contentType: 'application/json',
                data: JSON.stringify({'coupon': self.couponCode.trim()}),
                success: function (response) {
                    if (self.$store.state.userLoggedIn)
                        self.getPaymentInformation();
                    else {
                        // self.paymentAmount = response['payable_amount'];
                        // self.originalAmount = response['original_amount'];
                        self.paymentAmount = response['payable_amount'];
                        self.originalAmount = response['original_amount'];
                        self.appliedCouponCode = response['coupon'][0];
                    }
                    self.couponCode = '';
                    self.$store.state.loadingScreen = false;
                },
                error: function (error) {
                    self.couponCode = '';
                    if (error.responseJSON['message'] !== undefined)
                        self.paymentError = error.responseJSON['message'];
                    else {
                        self.paymentError = error;
                    }
                    self.$store.state.loadingScreen = false;
                },
            });
        },
        isNumber: function (evt) {
            if (this.card.exp.length === 2) {
                this.card.exp = this.card.exp + '/';
            }
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                evt.preventDefault();
            } else {
                return true;
            }
        },
        validateWithoutCard: function () {
            let self = this;
            let valid = true;
            if (!self.$store.state.userLoggedIn) {
                if (self.selectedLoginTab === 'Register') {
                    if (self.fullName.trim() === '' || self.emailId.trim() === '' || self.password.trim() === '' ||
                        self.confirmPassword.trim() === '') {
                        self.errors.push('Please enter all fields to create a new account to proceed with the payment');
                        valid = false;
                    }
                } else if (self.selectedLoginTab === 'Login') {
                    if (self.emailId.trim() === '' || self.password.trim() === '') {
                        self.errors.push('Please enter Email id and password to login to proceed with the payment');
                        valid = false;
                    }
                }
            }
            if (valid) {
                if (self.$store.state.userLoggedIn) {
                    self.byPassPayment();
                } else {
                    if (self.selectedLoginTab === 'Login') {
                        self.login(true, 0).then(response => {
                            let name = response['name'].split(' ');
                            self.customerFirstName = name[0];
                            if (response.length > 1)
                                self.customerLastName = name[1];
                            self.customerEmail = response['email'];
                            self.byPassPayment();
                        });
                    } else if (self.selectedLoginTab === 'Register') {
                        self.createAccount(true, 0).then(response => {
                            self.byPassPayment();
                        });
                    }
                }
            }
        },
        byPassPayment: function () {
            let self = this;
            self.$store.state.loadingScreen = true;
            let url = self.getBaseUrl() + '/accounts/bypass_payment/';
            $.ajax({
                url: url,
                beforeSend: function (request) {
                    if (self.$store.state.userLoggedIn)
                        request.setRequestHeader('Authorization', 'Token ' + self.getAuthToken());
                },
                type: 'GET',
                success: function (response) {
                    self.setIsPremiumUser(false);
                    self.$store.state.loadingScreen = false;
                    self.$router.push('/skillsvista_fit/');
                },
                error: function (error) {
                    error;
                    self.$store.state.loadingScreen = false;
                },
            });
        },
        validate() {
            this.errors = [];
            let self = this;
            let valid = true;
            if (!self.$store.state.userLoggedIn) {
                if (self.selectedLoginTab === 'Register') {
                    if (self.fullName.trim() === '' || self.emailId.trim() === '' || self.password.trim() === '' ||
                        self.confirmPassword.trim() === '') {
                        self.errors.push('Please enter all fields to create a new account to proceed with the payment');
                        valid = false;
                    }
                } else if (self.selectedLoginTab === 'Login') {
                    if (self.emailId.trim() === '' || self.password.trim() === '') {
                        self.errors.push('Please enter Email id and password to login to proceed with the payment');
                        valid = false;
                    }
                }
            }
            if (valid) {
                if (self.$store.getters.getUserLoggedIn && !self.customerFirstName) {
                    valid = false;
                    self.errors.push('Customer name is required');
                }
                if (self.$store.getters.getUserLoggedIn && !self.customerEmail) {
                    valid = false;
                    self.errors.push('Customer email is required');
                }
                if (!self.card.number) {
                    valid = false;
                    self.errors.push('Card Number is required');
                }
                if (!self.card.cvc) {
                    valid = false;
                    self.errors.push('CVC is required');
                }
                if (!self.card.exp) {
                    valid = false;
                    self.errors.push('Expiration date is required');
                }
                if (!self.checked) {
                    valid = false;
                    self.errors.push('Please check the terms and conditions');
                }
            }

            if (valid) {
                if (self.$store.state.userLoggedIn) {
                    self.createToken();
                } else {
                    self.$store.state.loadingScreen = true;
                    if (self.selectedLoginTab === 'Login') {
                        self.login(true, self.paymentAmount).then(response => {
                            if (response.user_type.toString() === '1') {
                                self.$router.push('/skillsvista_fit/');
                            } else {
                                self.customerFirstName = response['first_name'];
                                self.customerLastName = response['last_name'];
                                self.customerEmail = response['email'];
                                self.createToken();
                            }
                        });
                    } else if (self.selectedLoginTab === 'Register') {
                        self.$store.state.loadingScreen = true;
                        self.createAccount(true, self.paymentAmount).then(response => {
                            self.customerFirstName = response['first_name'];
                            self.customerLastName = response['last_name'];
                            self.customerEmail = response['email'];
                            self.$store.state.loadingScreen = true;
                            self.createToken();
                        });
                    }
                }
            }
        },
        createToken() {
            let self = this;
            self.$store.state.loadingScreen = true;
            self.stripeCheck = true;
            window.Stripe.setPublishableKey(self.stripePublishableKey);
            window.Stripe.createToken(self.card, (status, response) => {
                if (response.error) {
                    self.stripeCheck = false;
                    self.$message({
                        title: 'Unsuccessful',
                        message: response.error.message,
                        type: 'error',
                        duration: 3000,
                        showClose: true,
                    });
                    self.$store.state.loadingScreen = false;
                } else {
                    const payload = {
                        coupon: self.appliedCouponCode,
                        token: response.id,
                        amount: self.paymentAmount,
                        billing_details: {
                            name: self.customerFirstName + ' ' + self.customerLastName,
                            email: self.customerEmail,
                            phone: self.customerPhone,
                        },
                    };
                    const path = this.getBaseUrl() + '/accounts/charge/';
                    $.ajax({
                        beforeSend: function (request) {
                            request.setRequestHeader('Authorization',
                                'Token ' + self.getAuthToken());
                        },
                        contentType: 'application/json',
                        url: path,
                        type: 'POST',
                        data: JSON.stringify(payload),
                        success: function (res) {
                            self.$analytics.fbq.event('Purchase', {
                                name: self.$store.state.studentFullName,
                                email: self.$store.state.studentEmail,
                                amountPaid: self.paymentAmount,
                                currency: 'euro',
                            });
                            self.$store.state.loadingScreen = false;
                            self.$router.push({path: '/skillsvista_fit/'});
                        },
                        error: function (error) {
                            self.$store.state.loadingScreen = false;
                            error;
                        },
                    });
                }
            });
        },
    },
};
</script>
<style>
.cardNumber > .el-input__inner {
    border: none !important;
}

.el-input__prefix {
    height: 100% !important;
}
</style>

<style scoped>
.month-year-text:focus {
    border: none !important;
}

.month-year-text:active {
    border: none !important;
}

.month-year-text {
    display: inline-block;
    width: 100%;
    margin: 5px auto;
    border: none !important;
    padding: 5px 1px;
    text-align: center;
}

.strikethrough {
    position: relative;
}

.strikethrough:before {
    width: 100%;
    position: absolute;
    content: "";
    left: 0;
    top: 50%;
    right: 0;
    border-top: 1px solid;
    border-color: inherit;
    /*-webkit-transform: rotate(-5deg);*/
    /*-moz-transform: rotate(-5deg);*/
    /*-ms-transform: rotate(-5deg);*/
    /*-o-transform: rotate(-5deg);*/
    /*transform: rotate(-5deg);*/
}
</style>
