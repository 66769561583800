<template>
    <el-row style="padding: 1vw;">
        <el-card :style="!isMobile()?{width: '60%', margin: '5% auto'}:{width: '95%', margin: '0 auto'}"
                 style="background-color: #428B98; ">
            <el-row :style="!isMobile()?{padding: '2vh 1vw'}:{padding: '2vh 5vw'}">
                <el-col :lg="10" :md="12" :sm="24" :xl="10" :xs="24">
                    <div>
                        <div class="base-timer">
                            <svg class="base-timer__svg" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                                <g class="base-timer__circle">
                                    <circle class="base-timer__path-elapsed" cx="50" cy="50" r="45"></circle>
                                    <path
                                            :class="remainingPathColor"
                                            :stroke-dasharray="circleDasharray"
                                            class="base-timer__path-remaining"
                                            d="
                                                M 50, 50
                                                m -45, 0
                                                a 45,45 0 1,0 90,0
                                                a 45,45 0 1,0 -90,0
                                            ">
                                    </path>
                                </g>
                            </svg>
                            <span class="base-timer__label">{{ formattedTimeLeft }}</span>
                        </div>
                    </div>
                </el-col>
                <el-col :lg="14" :md="12" :sm="24" :xl="14" :xs="24" class="main-col-1">
                    <el-row>
                        <el-col :lg="20" :md="24" :sm="24" :xl="20" :xs="24">
                            <div class="interest-fit-head"
                                 style="color: white !important; font-weight: bolder; text-align: left;">
                                Congratulations, your results are being generated!
                            </div>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :lg="20" :md="24" :sm="24" :xl="20" :xs="24">
                            <div class="interest-fit-desc" style="color: white !important; font-weight: normal;">
                                This process takes our algorithms approximately 1 minute, so grab a cup of tea while your
                                waiting and we look forward to seeing you back in a little while for the big reveal!
                            </div>
                        </el-col>
                    </el-row>
                </el-col>
            </el-row>
        </el-card>
    </el-row>

</template>

<script>
  const FULL_DASH_ARRAY = 283;
  const WARNING_THRESHOLD = 10;
  const ALERT_THRESHOLD = 5;

  const COLOR_CODES = {
    info: {
      color: 'green',
    },
    warning: {
      color: 'orange',
      threshold: WARNING_THRESHOLD,
    },
    alert: {
      color: 'red',
      threshold: ALERT_THRESHOLD,
    },
  };

  const TIME_LIMIT = 60;

  export default {
    name: 'RecommendationTimer',
    props: ['timePassed'],
    data() {
      return {
        timerInterval: null,
        timePassedLocal: 0,
      };
    },
    computed: {
      circleDasharray() {
        return `${(this.timeFraction * FULL_DASH_ARRAY).toFixed(0)} 283`;
      },
      formattedTimeLeft() {
        const timeLeft = this.timeLeft;
        const minutes = Math.floor(timeLeft / 60);
        let seconds = timeLeft % 60;
        if (seconds < 10) {
          seconds = `0${seconds}`;
        }
        return `${minutes}:${seconds}`;
      },
      timeLeft() {
        return TIME_LIMIT - this.timePassedLocal;
      },
      timeFraction() {
        const rawTimeFraction = this.timeLeft / TIME_LIMIT;
        return rawTimeFraction - (1 / TIME_LIMIT) * (1 - rawTimeFraction);
      },
      remainingPathColor() {
        const {alert, warning, info} = COLOR_CODES;
        if (this.timeLeft <= alert.threshold) {
          return alert.color;
        } else if (this.timeLeft <= warning.threshold) {
          return warning.color;
        } else {
          return info.color;
        }
      },
    },
    watch: {
      timeLeft(newValue) {
        if (newValue === 0) {
          this.onTimesUp();
        }
      },
      timePassed(value) {
        let self = this;
        self.timePassedLocal = self.timePassed;
      },
    },
    mounted() {
      this.startTimer();
    },
    methods: {
      onTimesUp() {
        clearInterval(this.timerInterval);
        this.$emit('onTimeup');
      },
      startTimer() {
        let self = this;
        self.timePassedLocal = self.timePassed;
        self.timerInterval = setInterval(() => (self.timePassedLocal += 1), 1000);
      },
    },
  };
</script>

<style scoped>
    .base-timer {
        position: relative;
        width: 30vh;
        height: 30vh;
        display: inline-block;
    }

    .base-timer__svg {
        transform: scaleX(-1);
    }

    .base-timer__circle {
        fill: none;
        stroke: none;
    }

    .base-timer__path-elapsed {
        stroke-width: 7px;
        stroke: grey;
    }

    .base-timer__path-remaining {
        stroke-width: 7px;
        stroke-linecap: round;
        transform: rotate(90deg);
        transform-origin: center;
        transition: 1s linear all;
        fill-rule: nonzero;
        stroke: currentColor;
    }

    .base-timer__path-remaining.green {
        color: #41b883;
    }

    .base-timer__path-remaining.orange {
        color: orange;
    }

    .base-timer__path-remaining.red {
        color: red;
    }

    .base-timer__label {
        position: absolute;
        width: 30vh;
        height: 30vh;
        top: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 48px;
        color: white;
    }

</style>
