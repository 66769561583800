<template>
    <div>
        <div class="courseTopicTitle">Course Information</div>
<!--        <div class="career-name-info">-->
<!--            {{courseDetails.bio}}-->
<!--        </div>-->
        <el-collapse v-model="mActivePanel" accordion style="padding: 16px 0;">
            <el-collapse-item
                    v-for="(courseInfo, index) in getCourseArray"
                    :key="courseInfo.panelsName"
                    :title="courseInfo.panelsName"
                    v-bind:class="{isActivePanel : mActivePanel === index }"
                    :name="index">
                <div v-if="Array.isArray(courseInfo.panelContent)">
                    <ul>
                        <li v-for="(req, index) in courseInfo.panelContent" :key="index" class="career-name-info">
                            {{req}}
                        </li>
                    </ul>
                </div>
                <div v-else class="collapse-item-control career-name-info" v-html="courseInfo.panelContent">
                    NA
                </div>
            </el-collapse-item>
        </el-collapse>
    </div>
</template>


<script>
  export default {
    name: 'CourseInformation',
    props: {
      courseDetails: {},
    },
    computed: {
      getCourseArray: function() {
        return this.courseInfoArr;
      },
    },
    watch: {
      courseDetails: function(val) {
        let self = this;
        self.courseInfoArr.push({
          panelsName: 'Course Description',
          panelContent: val.bio,
        });
        self.courseInfoArr.push({
          panelsName: 'Requirements',
          panelContent: val.requirement_desc,
        });
        if (val.url !== '' && val.url !== undefined) {
          self.courseInfoArr.push({
            panelsName: 'College Website',
            panelContent: '<a target=\'_blank\' href=\'' + val.url + '\'>' + val.url + '</a>',
          });
        }
        self.courseInfoArr.push({
          panelsName: 'Career Sector',
          panelContent: val.careers_sector_keywords,
        });
        self.courseInfoArr.push({
          panelsName: 'Contact Center',
          panelContent: val.phone,
        });
      },
    },
    mounted() {
    },
    data: function() {
      return {
        mActivePanel: 0,
        component: {},
        courseInfoArr: [],
      };
    },
  };
</script>

<style></style>

<style scoped>
    .isActivePanel {
        background: #3a3f5c;
    }


    .collapse-item-control {
        background-color: #F0F1F9;
        text-align: left;
    }


    .el-collapse-item >>> .el-collapse-item__header {
        padding-left: 10px;
        padding-right: 10px;
    }

    .el-collapse-item >>> .el-collapse-item__content {
        text-align: left;
         padding-left: 10px;
        padding-right: 10px;
        background-color: #F0F1F9;
    }
</style>