<template>
    <div class="main-report">
        <el-row>
            <el-col class="yooni-fit-head-2 head-2">
                Your overall <strong>level {{getLevel()}}</strong> top 5 courses in <strong>Ireland</strong>
            </el-col>
        </el-row>
        <el-row :gutter="20" :style="{'padding': '10px'}">
            <div :key="courseName" @click="showCourseDetails(obj.id)" class="course-div"
                 v-for="(obj, courseName) in getTopIrelandCourses">
                <web-course-card :key="level + '-' + courseName" :collegeName="obj.college" :course-data="obj"></web-course-card>
            </div>
        </el-row>
        <el-row class="courseRow">
            <el-col class="yooni-fit-head-2 head-2">
                Your top 5 course fits in <strong>{{ getFilterCollegeName }}</strong>
            </el-col>
        </el-row>
        <el-row :gutter="20" :style="{'padding': '10px'}" v-if="getCourseFitData[filterGrade].length > 0">
            <div :key="courseName" @click="showCourseDetails(obj.id)" class="course-div"
                 v-for="(obj, courseName) in getCourseFitData[filterGrade]">
                <web-course-card :key="level + '-' + getFilterCollegeName" :collegeName="getFilterCollegeName" :course-data="obj"></web-course-card>
            </div>
        </el-row>
        <el-row v-else>
            <div style="text-align: left; padding: 1vw 0;">
                No courses found for the selected college
            </div>
        </el-row>
        <el-row>
            <el-col :span="12" class="yooni-fit-head-2 head-2">
                Show your top 5 courses in any college below
            </el-col>
        </el-row>
        <el-row>
            <div :key="index" :style="collegeName === getFilterCollegeName?{border: '1px solid #9698D6 !important'}:{}"
                @click="filterByCollegeName(collegeName)"
                class="college-div"
                v-for="(collegeName, index) in getCollegeList">
                <el-card :body-style="{ padding: '0px' }" class="college-card">
                    <el-col :span="8">
                        <CollegeImage :key="level + '-' + collegeName" :collegeName="collegeName" :collegesList="true" :publicPath="publicPath" :imgStyle="{width: '100%', height: '100%', padding: '10px'}"></CollegeImage>
                    </el-col>
                    <el-col :span="16" class="card-title-2">
                        {{collegeName}}
                    </el-col>
                </el-card>
            </div>
        </el-row>
    </div>
</template>

<script>
import CollegeImage from '../../CommonComponents/CollegeImage.vue';
import recommendationMixins from '../../mixins/recommendation_mixins';
import WebCourseCard from './WebCourseCard';
export default {
    name: 'WebCourseYooniFit',
    props: ['level'],
    components: { WebCourseCard, CollegeImage }, // Register the new component
    data: function() {
      return {
      };
    },
    mixins: [recommendationMixins],
    methods: {
        getLevel: function () {
            if (this.level.toString() !== '8')
                return '6/7'
        return this.level
        },
        showCourseDetails: function(id) {
            if(this.$store.state.userType.toString() !== '2')
                this.$router.push('/course/' + id + '/');
        },
        getOffset: function(index) {
            if (index === 0) {
                return 0;
            }
        }
    }
}
  </script>
<style>
    .college-card > .el-card__body {
        padding: 0 !important;
        height: 100%;
    }
</style>
<style scoped>


    .card-title-2 {
        font-family: Lato, serif;
        font-style: normal;
        font-weight: normal;
        font-size: 1vw;
        text-align: center;
        color: #000000;
        height: 100%;
        padding: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
    }


    .yooni-fit-head-2 {
        padding: 1vw 0;
        font-family: Niramit, serif !important;
        font-style: normal;
        font-weight: 300;
        color: #000000;
        text-align: justify !important;
    }

    .head-2 {
        font-size: 1.1vw;
        line-height: 1.1vw;
    }

    .course-div {
        overflow-y: hidden;
        float: left;
        width: 20%;
        padding-right: 1vw;
    }

    .college-div {
        float: left;
        width: calc(33.33% - 16px);
        margin: 1.5vh 16px 1.5vh 0;
        height: 12vh;
        background-color: white !important;
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .1);
        border: 1px solid #EBEEF5;
    }

    .college-card {
        box-shadow: none !important;
        border: none !important;
        background-color: transparent;
        height: 100%;
    }

    .college-div:hover {
        border: 1px solid #9698D6 !important;
        cursor: pointer;
        transform: scale(1.01);
    }

    .course-card:hover {
        border: 1px solid #9698D6 !important;
        cursor: pointer;
        /*transform: scale(1.02);*/
    }
</style>