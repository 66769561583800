<template>
    <el-card class="interest-fit">
        <el-row style="display: flex; align-items: center;">
            <el-col :span="20" class="courseTopicTitle">Personality Fit</el-col>
            <el-col :span="4" v-if="courseDetails.initials !== '' " class="test-labels">
                <div style="text-align: center; width: 100%;">{{courseDetails.initials}}</div>
            </el-col>
            <el-col :xl="2" :lg="2" :md="2" :sm="3" :xs="3" v-else-if="status === 0"
                 @click="$router.push({path: '/login/', query: { redirect: $route.fullPath }})">
                <div class="test-labels">
                    <img :src="`${publicPath}img/lock-image.svg`" style="width: 80%; margin: 0 auto;" alt="Lock">
                </div>
            </el-col>
        </el-row>
        <el-row>
            <el-col @click.native="$router.push('/personalities/')" :span="12"
                    v-for="(interestFit, index) in courseDetails.personalities" :key="index" class="careerName">
                <div v-if="!ignoredPersonalities.includes(interestFit)">
                    <div class="dot" :style="{backgroundColor: colorPalette[index]}"></div>
                    {{interestFit}}<i class="el-icon-arrow-right"></i></div>
            </el-col>
        </el-row>
    </el-card>
</template>

<script>
  export default {
    name: 'PersonalityFit',
    props: ['courseDetails', 'status'],
    data: function() {
      return {
        ignoredPersonalities: ['Naturalist', 'Verbal'],
        mapping: {
          'Enterprising': 'Enterprising',
          'Realist': 'Practical',
          'Administrative': 'Organiser',
          'Investigative': 'Thinker',
          'Social': 'Helper',
          'Creative': 'Artistic',
        },
        colorPalette: ['#CC0044', '#428B98', '#5C4C87', '#3D95CA', '#FDC300', '#B66289'],
      };
    },
    methods: {
      getPersonalityMapping: function(personality) {
        return this.mapping[personality];
      },
    },
  };
</script>

<style scoped>
    .interest-fit >>> .el-card__body {
        padding: 0 !important;
    }

    .dot {
        background-color: #bbb;
        border-radius: 50%;
        display: inline-block;
        vertical-align: middle;
    }

    .interest-fit {
        margin: 3vh 1vw;
        box-shadow: 0 0 0 0;
        background: #DEE1EE;
        border-radius: 4px;
        padding: 16px;
    }
</style>
