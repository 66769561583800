<template>
    <div>
        <el-row class="loginTitle">
            <el-col>Let’s Get Started!</el-col>
        </el-row>
        <el-row>
            <el-col>
                <div class="loginSubtitle">
                    Create a Skillsvista student account and get closer to your perfect course fit!
                </div>
            </el-col>
        </el-row>
        <el-row>
            <el-row style="padding: 0 1vw;">
                <el-row style="text-align: left !important;margin: 1vh 0 0;">
                    <label>Full Name</label>
                </el-row>
                <el-row style="margin: 0;">
                    <el-input :autocomplete="'on'" clearable for="name" placeholder=""
                              v-model="fullName"></el-input>
                </el-row>
                <el-row v-if="errorsFullName !== '' && errorsFullName !== undefined">
                    <div style="font-size: 10px; color: red; text-align: left">
                        {{errorsFullName}}
                    </div>
                </el-row>
                <el-row style="text-align: left !important;margin: 1vh 0 0;">
                    <label>Email ID</label>
                </el-row>
                <el-row style="margin: 0;">
                    <el-input :autocomplete="'on'" clearable for="email" placeholder=""
                              type="text"
                              v-model="emailId"></el-input>
                </el-row>
                <!--                            <el-row style="margin: 2vh 0;">-->
                <!--                                <el-input clearable placeholder="Your School" type="password" v-model="school"></el-input>-->
                <!--                            </el-row>-->
                <el-row v-if="errorsEmail !== '' && errorsEmail !== undefined">
                    <div style="font-size: 10px; color: red; text-align: left">
                        {{errorsEmail}}
                    </div>
                </el-row>
                <el-row style="text-align: left !important;margin: 1vh 0 0;">
                    <label>Password</label>
                </el-row>
                <el-row style="margin: 0;">
                    <el-input :autocomplete="'on'" clearable for="password" placeholder=""
                              show-password
                              type="password" v-model="password"></el-input>
                </el-row>
                <el-row v-if="errorsPassword !== '' && errorsPassword !== undefined">
                    <div style="font-size: 10px; color: red; text-align: left">
                        {{errorsPassword}}
                    </div>
                </el-row>
                <el-row style="text-align: left !important;margin: 1vh 0 0;">
                    <label>Confirm Password</label>
                </el-row>
                <el-row style="margin: 0;">
                    <el-input :autocomplete="'on'" clearable placeholder=""
                              type="password"
                              v-model="confirmPassword"></el-input>
                </el-row>
                <el-row v-if="errorsConfirmPassword !== '' && errorsConfirmPassword !== undefined">
                    <div style="font-size: 10px; color: red; text-align: left">
                        {{errorsConfirmPassword}}
                    </div>
                </el-row>
                <el-row style="text-align: left !important;margin: 1vh 0 0;">
                    <label>Referral Code(Optional)</label>
                </el-row>
                <el-row style="margin: 0;">
                    <el-input :autocomplete="'on'" clearable placeholder=""
                              type="text"
                              v-model="referralCode">

                    </el-input>
                </el-row>
                <el-row v-if="errorsReferralCode !== '' && errorsReferralCode !== undefined">
                    <div style="font-size: 10px; color: red; text-align: left">
                        {{errorsReferralCode}}
                    </div>
                </el-row>
                <el-row style="margin: 2vh 0 0;" v-if="!fromPayment">
                    <el-col>
                        <el-button @click="performCreateAccount()" class="login-btn">Create Account</el-button>
                    </el-col>
                </el-row>
                <el-row v-if="mainError !== '' && mainError !== undefined">
                    <el-col :key="type" v-for="(error, type) in mainError">
                        <ul>
                            <li style="font-size: 14px; color: red; text-align: left"
                                v-if="typeof error === 'string'">
                                {{error}}
                            </li>
                            <li :key="index" style="font-size: 12px; color: red; text-align: left"
                                v-else
                                v-for="(detailError, index) in error">
                                {{detailError}}
                            </li>
                        </ul>
                    </el-col>
                </el-row>
                <el-row style="margin: 2vh 0; font-size: 12px;">
                    <el-col :offset="8" :span="8">Or Sign Up Using</el-col>
                </el-row>
                <el-row style="margin: 2vh 0;">
                    <el-col :xl="{span: 3, offset: 9}" :lg="{span: 3, offset: 9}" :md="{span: 3, offset: 9}"
                            :sm="{span: 6, offset: 6}" :xs="{span: 6, offset: 6}">
                        <!--                        <el-button-->
                        <!--                                @click="authenticate('facebook', {'provider': 'facebook'})"-->
                        <!--                                class="btn-sign fb">Facebook-->
                        <!--                        </el-button>-->
                        <img :src="`${publicPath}img/facebook.svg`" alt="" style="cursor:pointer;"
                             @click="authenticate('facebook', {'provider': 'facebook'})">
                    </el-col>
                    <el-col :xl="3" :lg="3" :md="3" :sm="6" :xs="6">
                        <!--                        <el-button-->
                        <!--                                @click="authenticate('google', {'provider': 'google-oauth2'})"-->
                        <!--                                class="btn-sign gg">-->
                        <!--                            Google-->
                        <!--                        </el-button>-->
                        <img :src="`${publicPath}img/google.svg`" alt="" style="cursor:pointer;"
                             @click="authenticate('google', {'provider': 'google-oauth2'})">
                    </el-col>
                </el-row>
            </el-row>

        </el-row>
        <!--        <span class="not-member">-->
        <!--            Already have an account?-->
        <!--            <span @click="showLogin" class="signUp">Log in here</span>-->
        <!--        </span>-->
    </div>
</template>

<script>
  export default {
    name: 'SignUpInformation',
    props: ['fromPayment'],
    mounted() {
      const queryString = this.$route.query;
      if (queryString['code'] !== undefined) {
        this.referralCode = queryString['code']
      }
    },
    data: function() {
      return {
        referralCode: '',
        loadingScreen: false,
        emailId: '',
        password: '',
        confirmPassword: '',
        fullName: '',
        errorsFullName: '',
        errorsEmail: '',
        errorsPassword: '',
        errorsConfirmPassword: '',
        errorsReferralCode: '',
        mainError: '',
        page: {createAcc: '  Create an Account'},
      };
    },
    methods: {
      performCreateAccount: function() {
        let self = this;
        self.$store.state.loadingScreen = true;
        self.createAccount(true, self.paymentAmount).then(response => {
          self.$store.state.loadingScreen = false;
          self.$router.push('/');
        }).catch(error => {
          self.$store.state.loadingScreen = false;
        });
      },
      showLogin: function() {
        this.$router.push('/login/');
      },
    },
  };
</script>

<style scoped>

</style>
