<template>
    <div class="mobile-navbar-controls" style="background-color: #17484C;">
        <div class="icon-style" style="width: auto; padding: 15px 20px;height: 100%; ">
            <span @click.stop="changeCollapse()">
                <i aria-hidden="true" class="el-icon-s-unfold"
                   style="color: #ffffff; font-size: calc(2.7vw + 2.7vh) !important; line-height: 1;"></i>
            </span>
            <span class="collapse-text unselectable menu-title"></span>
        </div>
        <div @click="$router.push('/')" style="width: 65%;">
            <img :src="`${publicPath}img/sv_homepage.svg`" class="yooni-logo" width="125px"
                 style="height: 100%; padding: 0.5vh 0.5vw;">
        </div>
        <div style="width: 25%;">
            <profile-avatar></profile-avatar>
        </div>
    </div>
</template>

<style scoped>
    @import url("//unpkg.com/element-ui@2.13.2/lib/theme-chalk/index.css");

    .mobile-navbar-controls {
        background: #FFFFFF;
        z-index: 0 !important;
        margin-bottom: 0;
        display: flex;
        height: 60px;
        flex-flow: row;
        flex-direction: row;
        /*align-items: flex-end;*/
    }

    .el-icon-s-unfold, .el-icon-user-solid {
        line-height: 6vh;
    }

    .basic-sizing {
        font-size: 2rem;
    }
</style>

<script>
  import ProfileAvatar from './ProfileAvatar';

  export default {
    components: {ProfileAvatar},
    methods: {
      changeCollapse: function() {
        this.$emit('changeCollapse');
      },
    },
    name: 'mobile-navbar',
    props: ['collapse'],
  };
</script>