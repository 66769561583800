import HomePage from './Pages/BasicPages/HomePage';
import YooniFit from './Pages/BasicPages/YooniFit';
import CourseSearch from './Pages/CoursePages/CourseSearch.vue';
import PointsPredict from './Pages/BasicPages/PointsPredict';
import CourseFilter from './Pages/CoursePages/CourseFilter';
import Course from './Pages/CoursePages/Course';
import PersonalityTypes from './Pages/BasicPages/PersonalityTypes';
import LoginPage from './Pages/BasicPages/LoginPage';
import PaymentWall from './Pages/BasicPages/PaymentWall';

const CompanyInfo = () => import('./Pages/PartnersPages/CompanyInfo');
const CollegeInfo = () => import('./Pages/PartnersPages/CollegeInfo');
const SectorPage = () => import('./Pages/BasicPages/SectorPage');
const ProfileDetails = () => import('./Pages/BasicPages/ProfileDetails');
const TestQuestions = () => import('./Pages/AdminPages/TestQuestions');
const ViewEditCourseInformation = () => import('./Pages/AdminPages/ViewEditCourseInformation');
const UserLoginInfo = () => import('./Pages/AdminPages/UserLoginInfo');
const GenerateCoupons = () => import('./Pages/AdminPages/GenerateCoupons');
const UserFeedback = () => import('./Pages/BasicPages/UserFeedback');
const CounsellorHome = () => import('./Pages/CounsellorPages/CounsellorHome');
const CounsellorYooniFit = () => import('./Pages/CounsellorPages/CounsellorYooniFit');
const CounsellorPointsPredict = () => import('./Pages/CounsellorPages/CounsellorPointsPredict');
const CounsellorAssessments = () => import('./Pages/CounsellorPages/CounsellorAssessments');
const CAOForm = () => import('./Pages/BasicPages/CAOForm');
const LeaderBoard = () => import('./Pages/BasicPages/LeaderBoard');

const routes = [
  {
    path: '/counsellor/home/',
    component: CounsellorHome,
    name: 'CounsellorHome',
  },
  {
    path: '/counsellor/report/:studentId/:studentName/',
    component: CounsellorYooniFit,
    name: 'CounsellorYooniFit',
    props: true,
  },
  {
    path: '/counsellor/subjects/:studentId/:studentName/',
    component: CounsellorPointsPredict,
    name: 'CounsellorPointsPredict',
    props: true,
  },
  {
    path: '/counsellor/assessments/:studentId/:studentName/',
    component: CounsellorAssessments,
    name: 'CounsellorAssessments',
    props: true,
  },
  {
    path: '/counsellor/cao-form/:studentId/:studentName/',
    component: CAOForm,
    name: 'CAOFormCounsellor',
    props: true,
  },
  {
    path: '/profile/',
    component: ProfileDetails,
    name: 'ProfileDetails',
  },
  {
    path: '/admin/',
    component: UserLoginInfo,
    name: 'AdminPanel',
  },
  {
    path: '/coupons/',
    component: GenerateCoupons,
    name: 'GenerateCoupons',
  },
  {
    path: '/course/edit/',
    component: ViewEditCourseInformation,
    name: 'ViewEditCourseInformation',
  },
  {
    path: '/questions/edit/',
    component: TestQuestions,
    name: 'TestQuestions',
  },
  {
    path: '/feedback/',
    component: UserFeedback,
    name: 'UserFeedback',
  },
  {
    path: '/login/',
    component: LoginPage,
    name: 'Login',
  },
  {
    path: '/sector',
    component: SectorPage,
    name: 'SectorPage',
  },
  {
    path: '/leaderboard/',
    component: LeaderBoard,
    name: 'LeaderBoard',
  },
  // {
  //   path: '/create-account/',
  //   component: Signup,
  //   name: 'SignUp',
  // },
  {
    path: '/',
    component: HomePage,
    name: 'HomePage',
  },
  {
    path: '/payment/',
    component: PaymentWall,
    name: 'PaymentWall',
  },
  // {
  //   path: '/help/',
  //   component: Help,
  //   name: 'Help',
  // },
  {
    path: '/skillsvista_fit/',
    component: YooniFit,
    name: 'YooniFit',
  },
  {
    path: '/points_predict/',
    component: PointsPredict,
    name: 'PointsPredict',
    props: true,
  },
  {
    path: '/course/search/',
    component: CourseSearch,
    name: 'course search',
  },
  {
    path: '/filter/',
    component: CourseFilter,
    name: 'course filter',
    props: true,
  },
  {
    path: '/course/:courseId/',
    component: Course,
    props: true,
    name: 'Course',
  },
  {
    path: '/personalities/',
    component: PersonalityTypes,
    name: 'Personality',
    props: true,
  },
  {
    path: '/cao_form/',
    component: CAOForm,
    name: 'CAOForm',
  },
  {
    path: '/company/:companyName/',
    component: CompanyInfo,
    name: 'CompanyInfo',
    props: true,
  },
  {
    path: '/college/:collegeName/',
    component: CollegeInfo,
    name: 'CollegeInfo',
    props: true,
  },
];
export default {
  routes,
};
